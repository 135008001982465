<mat-radio-group style="display:flex; flex-direction: column; overflow: hidden" [(ngModel)]="SelectedDateOption" (change)="OnSelectedDateOptionChanged()" [disabled]="isDisabled">
    <mat-radio-button *ngIf="allowEmpty" style="padding-bottom:10px" [value]="FilterOptions.None" color="primary">All Dates</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowToday" style="padding-bottom:10px" [value]="FilterOptions.Today" color="primary">Today</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowYesterday" style="padding-bottom:10px" [value]="FilterOptions.Yesterday" color="primary">Yesterday</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowLastWeek" style="padding-bottom:10px" [value]="FilterOptions.LastWeek" color="primary">Last Week (last Sunday to last Saturday)</mat-radio-button>

    <mat-radio-button *ngIf="DateFilterConfig.AllowLastMonth" style="padding-bottom:10px" [value]="FilterOptions.LastMonth" color="primary">Last Month</mat-radio-button>

    <div *ngIf="DateFilterConfig.AllowPastDaysFromToday" style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.PastDaysFromToday" color="primary">Days before (and including) today:</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" class="input-control" (click)="OnInputClicked(FilterOptions.PastDaysFromToday)">
            <mat-label>Days</mat-label>
            <input matInput type="number" min="1" max="{{MaxDays}}" [iqFocus]="PastDaysFocusEvent"
                   [formControl]="PastDaysFormControl" autocomplete="off" />
            <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
            <mat-error iqValidationMessage [control]="PastDaysFormControl"></mat-error><!--I don't think there will ever be an error....-->
        </mat-form-field>
    </div>
    <div *ngIf="DateFilterConfig.AllowDaysBeforeAndNotIncludingToday" style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.DaysBeforeAndNotIncludingToday" color="primary">Days before (and NOT including) today:</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" class="input-control" (click)="OnInputClicked(FilterOptions.DaysBeforeAndNotIncludingToday)">
            <mat-label>Days</mat-label>
            <input matInput type="number" min="1" max="{{MaxDays}}" [iqFocus]="DaysBeforeAndNotIncludingTodayFocusEvent"
                   [formControl]="DaysBeforeAndNotIncludingTodayFormControl" autocomplete="off" />
            <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
            <mat-error iqValidationMessage [control]="DaysBeforeAndNotIncludingTodayFormControl"></mat-error><!--I don't think there will ever be an error....-->
        </mat-form-field>
    </div>

    <div *ngIf="DateFilterConfig.AllowPastHoursFromNow" style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.PastHoursFromNow" color="primary">Hours before current time:</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" class="input-control" (click)="OnInputClicked(FilterOptions.PastHoursFromNow)">
            <mat-label>Hours</mat-label>
            <input matInput type="number" min="1" max="{{MAX_HOURS}}" [iqFocus]="PastHoursFocusEvent"
                   [formControl]="PastHoursFormControl" autocomplete="off" />
            <mat-hint>Max {{MAX_HOURS}}</mat-hint>
            <mat-error iqValidationMessage [control]="PastHoursFormControl"></mat-error><!--I don't think there will ever be an error....-->
        </mat-form-field>
    </div>

    <div *ngIf="DateFilterConfig.AllowSingleDate" style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.SingleDate" color="primary">Single Date:</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" class="date-input-control" (click)="OnInputClicked(FilterOptions.SingleDate); pickerSingle.open()">
            <mat-label>Date</mat-label>
            <input iqDisableAutofill matInput [mtxDatetimepicker]="pickerSingle" (dateChange)="OnSingleDateChanged()"
                   [formControl]="SingleDateFormControl" />
            <mtx-datetimepicker-toggle matSuffix [for]="pickerSingle"></mtx-datetimepicker-toggle>
            <mtx-datetimepicker type="date" mode="portrait" #pickerSingle></mtx-datetimepicker>
            <mat-hint>(mm/dd/yyyy)</mat-hint>
            <mat-error iqValidationMessage [control]="SingleDateFormControl"></mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="DateFilterConfig.AllowDateRange" style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.DateRange" color="primary">Date Range:</mat-radio-button>
        <div style="display:flex; flex-wrap: wrap">
            <mat-form-field subscriptSizing="dynamic" class="date-input-control" (click)="OnInputClicked(FilterOptions.DateRange); pickerRangeStart.open()">
                <mat-label>Start Date</mat-label>
                <input iqDisableAutofill matInput [mtxDatetimepicker]="pickerRangeStart" (dateChange)="OnDateRangeChanged()"
                       [formControl]="DateRangeStartFormControl" />
                <mtx-datetimepicker-toggle matSuffix [for]="pickerRangeStart"></mtx-datetimepicker-toggle>
                <mtx-datetimepicker type="date" mode="portrait" #pickerRangeStart></mtx-datetimepicker>
                <mat-hint>(mm/dd/yyyy)</mat-hint>
                <mat-error iqValidationMessage [control]="DateRangeStartFormControl"></mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" class="date-input-control" (click)="OnInputClicked(FilterOptions.DateRange); pickerRangeEnd.open()">
                <mat-label>End Date</mat-label>
                <input iqDisableAutofill matInput [mtxDatetimepicker]="pickerRangeEnd" (dateChange)="OnDateRangeChanged()" [min]="DateRangeStartFormControl.value" [formControl]="DateRangeEndFormControl" />
                <mtx-datetimepicker-toggle matSuffix [for]="pickerRangeEnd"></mtx-datetimepicker-toggle>
                <mtx-datetimepicker type="date" mode="portrait" #pickerRangeEnd></mtx-datetimepicker>
                <mat-hint>(mm/dd/yyyy)</mat-hint>
                <mat-error iqValidationMessage [control]="DateRangeEndFormControl"></mat-error>
            </mat-form-field>
        </div>
    </div>

    <mat-radio-button *ngIf="DateFilterConfig.AllowTomorrow" style="padding-bottom:10px" [value]="FilterOptions.Tomorrow" color="primary">Tomorrow</mat-radio-button>

    <div *ngIf="DateFilterConfig.AllowDaysFromToday">
        <div style="display: flex; align-items: baseline">
            <mat-radio-button [value]="FilterOptions.DaysFromToday" color="primary">Days after (and including) today:</mat-radio-button>
            <mat-form-field subscriptSizing="dynamic" class="input-control" (click)="OnInputClicked(FilterOptions.DaysFromToday)">
                <mat-label>Days</mat-label>
                <input matInput type="number" min="1" max="{{MaxDays}}" [iqFocus]="NextDaysFocusEvent"
                       [formControl]="NextDaysFormControl" autocomplete="off" />
                <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
                <mat-error iqValidationMessage [control]="NextDaysFormControl"></mat-error><!--I don't think there will ever be an error....-->
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="DateFilterConfig.AllowDaysAfterAndNotIncludingToday">
        <div style="display: flex; align-items: baseline">
            <mat-radio-button [value]="FilterOptions.DaysAfterAndNotIncludingToday" color="primary">Days after (and NOT including) today:</mat-radio-button>
            <mat-form-field subscriptSizing="dynamic" class="input-control" (click)="OnInputClicked(FilterOptions.DaysAfterAndNotIncludingToday)">
                <mat-label>Days</mat-label>
                <input matInput type="number" min="1" max="{{MaxDays}}" [iqFocus]="DaysAfterAndNotIncludingTodayFocusEvent"
                       [formControl]="DaysAfterAndNotIncludingTodayFormControl" autocomplete="off" />
                <mat-hint *ngIf="MaxDays">Max {{MaxDays}}</mat-hint>
                <mat-error iqValidationMessage [control]="DaysAfterAndNotIncludingTodayFormControl"></mat-error><!--I don't think there will ever be an error....-->
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="DateFilterConfig.AllowHoursFromNow">
        <div *ngIf="!DateOnly" style="display: flex; align-items: baseline">
            <mat-radio-button [value]="FilterOptions.HoursFromNow" color="primary">Hours after current time:</mat-radio-button>
            <mat-form-field subscriptSizing="dynamic" class="input-control" (click)="OnInputClicked(FilterOptions.HoursFromNow)">
                <mat-label>Hours</mat-label>
                <input matInput type="number" min="1" max="{{MAX_HOURS}}" [iqFocus]="NextHoursFocusEvent"
                       [formControl]="NextHoursFormControl" autocomplete="off" />
                <mat-hint>Max {{MAX_HOURS}}</mat-hint>
                <mat-error iqValidationMessage [control]="NextHoursFormControl"></mat-error><!--I don't think there will ever be an error....-->
            </mat-form-field>
        </div>
    </div>
</mat-radio-group>
