export enum DeviceFormFactorEnum {
    Desktop = 1,
    Tablet = 2,
    Phone = 3,

    /**
     *  Ticket created via external ticket creation API
     */
    API = 10
}

export enum DeviceFormFactorEnumDescriptions {
    Desktop = "Desktop",
    Tablet = "Tablet",
    Phone = "Phone",
    API = "API"
}
