<div style="display:flex; flex-direction:column; column-gap: 20px;">

    <!-- matAutocompleteOrigin causes the width of the autocomplete to fill the entire div - instead of being limited to the width of the input -->
    <div matAutocompleteOrigin #responseArea="matAutocompleteOrigin" style="display:flex">
        <mat-form-field>
            <mat-label>Response</mat-label>
            <input #responseInput iq-select-on-blur="Code" UpperCase type="text" matInput [formControl]="ResponseFormControl"
                   [matAutocomplete]="responseAuto" [matAutocompleteConnectedTo]="responseArea" (click)="ShowPanel()">
            <mat-autocomplete autoActiveFirstOption class="iq-autocomplete" #responseAuto="matAutocomplete" [displayWith]="ResponseDisplayFn">
                <mat-option class="allow-wrap" *ngFor="let responseOption of FilteredResponses | async" [value]="responseOption">
                    <div><span class="iq-subheading-2">{{ responseOption.Code }}</span> - {{ responseOption.Description }}</div>
                </mat-option>
            </mat-autocomplete>
            <mat-error iqValidationMessage [control]="ResponseFormControl"></mat-error>
        </mat-form-field>
        <div class="iq-caption gray-primary-text" style="padding-left:20px; padding-top:10px">{{ResponseFormControl.value?.Description}}</div>
    </div>

    <ng-template dynamic-content></ng-template>

    <mat-form-field>
        <mat-label>Comment</mat-label>
        <input #commentInput iqDisableAutofill matInput UpperCase [formControl]="CommentFormControl" />
        <mat-error iqValidationMessage [control]="CommentFormControl"></mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="IsLocalUser">
        <mat-label>Respondant Person Name</mat-label>
        <input iqDisableAutofill matInput UpperCase [formControl]="RespondantFormControl" />
        <mat-error iqValidationMessage [control]="RespondantFormControl"></mat-error>
    </mat-form-field>
    <div *ngIf="!Data.SingleItem" style="display: flex; flex-wrap: wrap; width: 100%">
        <div *ngIf="AffectedTicketNumbers.length > 0" style="width: 100%;">
            <label style="width: 100%;">
                <b>Affected Tickets:</b>
            </label>
            <br />
            <div style="width: 100%;">{{AffectedTicketNumbers.join(', ')}}</div>
            <br />
        </div>
        <div *ngIf="UnaffectedTicketNumbers.length > 0" style="width: 100%;">
            <label style="width: 100%; color: red;">
                <b>Tickets that are unavailable for this Service Area/Utility Type:</b>
            </label>
            <br />
            <div style="color: red; width: 100%;">{{UnaffectedTicketNumbers.join(', ')}}</div>
        </div>
    </div>
</div>
