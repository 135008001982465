import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DisclaimerTypeEnum } from 'Enums/DisclaimerType.enum';
import { TicketActionEnum } from 'Enums/TicketAction.enum';
import { TicketFunctionActionEnum } from 'Enums/TicketFunctionAction.enum';
import { TicketStatusEnum } from 'Enums/TicketStatus.enum';
import { TicketFunction } from 'Models/Configuration/TicketFunction.model';
import { AllowedTicketActionsRequest } from "Models/Tickets/AllowedTicketActionsRequest.model";
import { CancelTicketRequest } from 'Models/Tickets/CancelTicketRequest.model';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';
import { TicketEntryResponse } from 'Models/Tickets/TicketEntryResponse.model';
import { TicketFunctionDialogData } from 'Models/Tickets/TicketFunctionDialogData.model';
import { VoidTicketRequest } from "Models/Tickets/VoidTicketRequest.model";
import { DisclaimerService } from 'Pages/System/Services/Disclaimer.service';
import { Observable, of } from "rxjs";
import { map, mergeMap, take } from 'rxjs/operators';
import { ComponentLookupRegistry } from 'Services/ComponentLookup.service';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';
import { SettingsService } from 'Services/SettingsService';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { QAStatusEnum, QAStatusEnumDescription } from '../../../Enums/QAStatus.enum';
import { SelectOption } from '../../../Models/Configuration/SelectOption.model';
import { IEntity } from '../../../Models/Interfaces/IEntity.interface';
import { SearchColumn } from '../../../Models/Searching/SearchColumn.model';
import { MarkCompletedRequest } from '../../../Models/Tickets/MarkCompletedRequest.model';
import { PrintingService } from '../../../Services/Printing.service';
import { SideSlideoutService } from '../../../Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { ConfirmReleaseSuspendedTicketDialogComponent } from '../Details/Components/ConfirmReleaseSuspendedTicketDialog/ConfirmReleaseSuspendedTicketDialog.component';
import { ConfirmVoidTicketDialogComponent, ConfirmVoidTicketDialogResponse } from '../Details/Components/ConfirmVoidTicketDialog/ConfirmVoidTicketDialog.component';
import { TicketEntryFormGroup } from '../Details/Components/InputControls/TicketEntryFormGroup';
import { TicketCancelDialogComponent } from '../Details/Components/TicketCancel/Dialog/TicketCancelDialog.component';
import { TicketCancelFlyoutComponent } from '../Details/Components/TicketCancel/Flyout/TicketCancelFlyout.component';
import { TicketCancelData } from "../Details/Components/TicketCancel/TicketCancel.component";
import { TicketCanceledConfirmationDialogComponent } from '../Details/Components/TicketCancel/TicketCanceledConfirmation/TicketCanceledConfirmationDialog.component';
import { TicketFunctionDialogComponent } from '../Details/Components/TicketFunction/Dialog/TicketFunctionDialog.component';
import { TicketFunctionFlyoutComponent } from '../Details/Components/TicketFunction/Flyout/TicketFunctionFlyout.component';
import { ITicketFunctionContentView } from '../Details/Components/TicketFunction/ITicketFunctionContentView.interface';
import { StartTicketFunctionData } from '../Details/Components/TicketFunction/StartTicketFunctionData.model';
import { AddPositiveResponseData } from '../Responses/AddPositiveResponse/AddPositiveResponse.component';
import { AddPositiveResponseDialog } from '../Responses/AddPositiveResponse/Dialog/AddPositiveResponseDialog.component';
import { AddPositiveResponseFlyoutComponent } from '../Responses/AddPositiveResponse/Flyout/AddPositiveResponseFlyout.component';
import { TicketResponseServiceAreaListDialog } from '../Responses/Dialogs/TicketResponseServiceAreaList/Dialog/TicketResponseServiceAreaListDialog.component';
import { TicketResponseServiceAreaListFlyout } from '../Responses/Dialogs/TicketResponseServiceAreaList/Flyout/TicketResponseServiceAreaListFlyout.component';
import { TicketResponseServiceAreaListData } from '../Responses/Dialogs/TicketResponseServiceAreaList/TicketResponseServiceArea.component';
import { TicketListListActions } from '../Search/Desktop/DesktopTicketListBase.component';
import { TicketService } from './TicketService';

//  Need this scoped to the TicketsModule because it uses a dialog (to cancel tickets) that is in this module.
//  If we use "angular providedIn: 'root'", it will fail to load TicketCancelDialogComponent
//  See: https://github.com/angular/components/issues/8473#issuecomment-415237247
//  This service has no state anyway so we don't need a singleton of it...
@Injectable({ providedIn: 'root' })
export class TicketActionsService {
    private _FormIsBusy: boolean = false;

    constructor(private _HttpClient: HttpClient, private _SettingsService: SettingsService, private _Dialog: MatDialog,
        private _Router: Router, private _TicketService: TicketService, private _DeviceDetectorService: DeviceDetectorService,
        private _SideSlideoutService: SideSlideoutService, private _PrintingService: PrintingService, private _DisclaimerService: DisclaimerService) {
    }

    public GetAllowedTicketActions(ticketIDs: string[]): Observable<TicketEntryAllowedTicketActions> {
        if (!ticketIDs || (ticketIDs.length === 0))
            return of(null);

        const request: AllowedTicketActionsRequest = new AllowedTicketActionsRequest(ticketIDs);
        return this._HttpClient.post<TicketEntryAllowedTicketActions>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/AllowedTicketActions/", request)
    }

    public GetAllowedTicketActionsMenuItems(ticketItemList: any[], onRefreshSearch: () => void,
        onMarkTicketsCompleted: (completed: boolean) => void, displayedColumns: SearchColumn[], onDeleteItem: (entity: IEntity) => void, deleteRowsForCompletedResponses: boolean, onClearSelected: () => void): Observable<SelectOption[]>
    {
        if (!ticketItemList || ticketItemList.length === 0)
            return of([]);

        const ticketIDs = ticketItemList.map(t => t.ID as string);
        const singleItem = ticketItemList.length === 1 ? ticketItemList[0] : null;      //  null if more than 1 provided
        const isMobile = this._DeviceDetectorService.IsPhone;

        return this.GetAllowedTicketActions(ticketIDs)
            .pipe(
                take(1),
                map((allowedActions: TicketEntryAllowedTicketActions) => {
                    let list: SelectOption[] = [];
                    let needDivider = false;

                    if (singleItem) {
                        list.push(new SelectOption({ OnClick: () => { this.ViewTicket(singleItem.ID) } }, "View Ticket", "View"));
                        needDivider = true;
                    }

                    if (singleItem?.IsLast === "No") {
                        list.push(new SelectOption({ OnClick: () => { this.ViewMostRecent(singleItem.TicketNumber) } }, "View Most Recent Version", "ViewMostRecent"));
                        needDivider = true;
                    }

                    if (!isMobile) {
                        if (singleItem?.ParentTicketID || (singleItem?.IsLast === "No")) {
                            list.push(new SelectOption(TicketListListActions.ViewAllRelated, "Find All Related"));
                            needDivider = true;
                        }
                    }

                    if (needDivider)
                        list.push(null);        //  Makes a horizontal line
                    needDivider = false;

                    if (allowedActions) {
                        if (this._SettingsService.UsesPositiveResponse) {
                            if (allowedActions.CanAddResponses) {
                                list.push(new SelectOption({ OnClick: () => { this.AddPositiveResponse(ticketItemList, displayedColumns, onDeleteItem, deleteRowsForCompletedResponses, onClearSelected) } }, "Add Response", "note_add"));
                                needDivider = true;
                            }
                            if (allowedActions.CanViewResponses) {
                                const onClick = () => {
                                    //  Need to clone the allowed actions and disable CanAddResponses because we don't want to allow adding responses from the list view
                                    //  if the user is both an excavator and a service area user.
                                    const clonedAllowedActions = JSON.parse(JSON.stringify(allowedActions));
                                    clonedAllowedActions.CanAddResponses = false;     //  Can't add responses from the list view
                                    const data = new TicketResponseServiceAreaListData(clonedAllowedActions, singleItem);
                                    if (this._DeviceDetectorService.IsPhone)
                                        this._SideSlideoutService.AttachComponent("Right", TicketResponseServiceAreaListFlyout, data);
                                    else {
                                        this._Dialog.open(TicketResponseServiceAreaListDialog, {
                                            data: data,
                                            width: '90%',
                                            maxWidth: '90%',
                                            height: '90%'
                                        });
                                    }
                                }

                                list.push(new SelectOption({ OnClick: onClick }, "View Responses", "description"));
                                needDivider = true;
                            }
                        }
                     
                        if (allowedActions.CanMarkWorkCompleted) {
                            this.AddWorkCompleteMenuOptionToList(list, true, ticketItemList, ticketIDs, onMarkTicketsCompleted);
                            needDivider = true;
                        }
                        if (allowedActions.CanMarkWorkNotCompleted) {
                            this.AddWorkCompleteMenuOptionToList(list, false, ticketItemList, ticketIDs, onMarkTicketsCompleted);
                            needDivider = true;
                        }

                        if (needDivider)
                            list.push(null);        //  Makes a horizontal line
                        needDivider = false;

                        if (singleItem) {
                            if (allowedActions.CanUnlock) {
                                const onClick = () => {
                                    this.UnlockTicket(singleItem.ID)
                                        .subscribe(() => {
                                            //  Reset these to reflect the change that was just made.
                                            singleItem.LockedDate = null;
                                            singleItem.LockedByPerson_Fullname = null;
                                        });
                                };
                                let unlockText = "Unlock";
                                if (allowedActions.LockedByUserName && allowedActions.LockedByUserName !== "")
                                    unlockText += " (locked by " + allowedActions.LockedByUserName + ")";
                                list.push(new SelectOption({ OnClick: onClick }, unlockText, "Unlock"));
                            } else if (allowedActions.LockedByAnotherUser)
                                list.push(new SelectOption('', TicketActionsService.LockedByMessage(allowedActions)));

                            allowedActions.AllowedTicketEditFunctions.forEach(tf => {
                                const onClick = () => { this.DoTicketFunction(singleItem.TicketNumber, tf, false, () => onRefreshSearch, null) };
                                list.push(new SelectOption({ OnClick: onClick }, tf.Name, "TicketFunction-" + tf.Name));
                            });

                            if (allowedActions.CanCopy) {
                                const onClick = () => { this.CopyTicket(singleItem.TicketNumber) };
                                list.push(new SelectOption({ OnClick: onClick }, "Copy", "Copy"));
                            }

                            if (allowedActions.CanEditSuspend) {
                                const onClick = () => { this.EditOrResumeTicket(singleItem.TicketNumber) };
                                list.push(new SelectOption({ OnClick: onClick }, "Edit", "TicketFunction-Edit"));
                            }

                            if (allowedActions.CanResumeIncomplete) {
                                const onClick = () => { this.EditOrResumeTicket(singleItem.TicketNumber) };
                                list.push(new SelectOption({ OnClick: onClick }, "Resume", "Resume"));
                            }

                            if (allowedActions.CanQueueForQA) {
                                const onClick = () => {
                                    this.QATicket(singleItem.ID)
                                        .subscribe(() => {
                                            //  Update this in case it's being displayed in the list
                                            singleItem.QAStatus = QAStatusEnumDescription[QAStatusEnum[QAStatusEnum.ManuallyQueued]];
                                        });
                                };
                                list.push(new SelectOption({ OnClick: onClick }, "Queue for QA"));
                            }

                            if (allowedActions.CanPrint) {
                                //  Only add horizontal line if the last item is not one too!  (happens when can view ticket but not do any edit actions)
                                if (!isMobile && (list.length > 0) && (list[list.length - 1] !== null))
                                    list.push(null);
                                list.push(new SelectOption({ OnClick: () => { this.PrintDocument(singleItem.ID) } }, "Print Text", "print"));
                            }
                        }
                    }

                    if (!list[list.length - 1])
                        list = list.splice(0, list.length - 1);
                    return list;
                })
            );
    }

    private AddWorkCompleteMenuOptionToList(list: SelectOption[], forMarkWorkComplete: boolean, ticketItemList: any[],
        ticketIDs: string[], onMarkTicketsCompleted: (completed: boolean) => void): void
    {
        const onclick = () => {
            this.MarkWorkCompleted(ticketIDs, forMarkWorkComplete).pipe(take(1)).subscribe((allowedActions) => {
                //  If null, user canceled dialog or did not have permission (which should not happen)
                if (allowedActions) {
                    ticketItemList.forEach(t => (t as any).IsWorkComplete = forMarkWorkComplete ? "Yes" : "No");
                    if (onMarkTicketsCompleted)
                        onMarkTicketsCompleted(forMarkWorkComplete);
                }
            });
        }

        const name = forMarkWorkComplete ? "Work is not Completed (click to toggle)" : "Work is Completed (click to toggle)";
        const icon = forMarkWorkComplete ? "WorkNotComplete" : "WorkComplete";
        const color = forMarkWorkComplete ? "red" : "green";

        list.push(new SelectOption({ OnClick: onclick }, name, icon, color));
    }

    //  Can be Ticket.ID or Ticket.TicketNumber.
    //  If TicketNumber, will view the most recent version.
    public ViewTicket(idOrTicketNumber: string): void {
        //  The api controller handles a TicketID or a TicketNumber (which will find the most recent version)
        this._Router.navigate(["/tickets/view/" + idOrTicketNumber]);
    }

    public ViewMostRecent(idOrTicketNumber: string): void {
        //  The api controller handles a TicketID or a TicketNumber (which will find the most recent version)
        this._Router.navigate(["/tickets/mostrecent/" + idOrTicketNumber]);
    }

    public DoTicketFunction(ticketNumber: string, ticketFunction: TicketFunction, fromTicketDetails: boolean,
        onTicketSaved: () => void, ticketEntryForm: TicketEntryFormGroup): void
    {
        switch (ticketFunction.Action) {
            case TicketFunctionActionEnum.Edit:
                if (!this.StartTicketFunctionCustomComponent(ticketNumber, ticketFunction, fromTicketDetails, onTicketSaved, ticketEntryForm))
                    this._Router.navigate(["/tickets/edit/" + ticketNumber + "/" + ticketFunction.ID]);
                break;
            case TicketFunctionActionEnum.Cancel:
                //  If coming from ticket list and !RequireViewTicket, navigate to "dialogedit" route to show the ticket.
                //  That will then initiate the ticket edit from the ticket details page (so the user can see the ticket behind the dialog).
                if (fromTicketDetails || !ticketFunction.RequireViewTicket)
                    this.ConfirmCancelTicket(ticketNumber, ticketFunction);
                else
                    this.ViewTicketAndStartTicketFunction(ticketNumber, ticketFunction);
                break;
            case TicketFunctionActionEnum.AddComments:
                //  This (at least currently) requires a custom TicketFunction Component.
                if (!this.StartTicketFunctionCustomComponent(ticketNumber, ticketFunction, fromTicketDetails, onTicketSaved, ticketEntryForm))
                    throw new Error("Ticket Function dialog component not registered for ticket function: " + ticketFunction.Name);
                break;
            default:
                throw new Error("Unhandled TicketFunctionAction");
        }
    }

    /**
     *  Check to see if there is a custom component for this ticket function.  If so, start the ticket function using that component (and return true).
     *  Otherwise, returns false and we will navigate to the main /Edit route to do the ticket edit using the full ticket entry form.
     */
    private StartTicketFunctionCustomComponent(ticketNumber: string, ticketFunction: TicketFunction, fromTicketDetails: boolean,
        onTicketSaved: () => void, ticketEntryForm: TicketEntryFormGroup): boolean
    {
        const contentViewComponent = this.GetTicketFunctionContentViewComponent(ticketFunction);
        const dialogRef = this.GetTicketFunctionDialogComponent(ticketFunction);        //  This is old way - remove this
        if (!contentViewComponent && !dialogRef)
            return false;

        //  If coming from ticket list and !RequireViewTicket, navigate to "dialogedit" route to show the ticket.
        //  That will then initiate the ticket edit from the ticket details page (so the user can see the ticket behind the dialog).
        if (fromTicketDetails || !ticketFunction.RequireViewTicket) {
            if (contentViewComponent)
                this.StartTicketFunctionUsingContentView(contentViewComponent, ticketNumber, ticketFunction, fromTicketDetails, onTicketSaved, ticketEntryForm);
            else
                this.OpenTicketFunctionDialog(dialogRef, ticketNumber, ticketFunction, fromTicketDetails, onTicketSaved, ticketEntryForm);
        } else
            this.ViewTicketAndStartTicketFunction(ticketNumber, ticketFunction);

        return true;
    }

    public PrintDocument(itemID: any): void {
        this._PrintingService.PrintDocument("Ticket", "tickets", ["print", "text", itemID]);
    }

    public AddPositiveResponse(listItems: any[], displayedColumns: SearchColumn[], onDeleteItem: (entity: IEntity) => void, deleteRowsForCompletedResponses: boolean, onClearSelected: () => void): void {
        const singleItem: boolean = listItems.length === 1;
        const listItem: any = listItems.length === 1 ? listItems[0] : null;
        const data = new AddPositiveResponseData(listItems.map(item => item.TicketNumber), singleItem ? listItem.TicketTypeID : null, singleItem);

        //  These properties are used to limit the Service Area(s) and Utility Type to the item in the list.
        //  If we don't do this and the user is linked to multiple service areas, it will let him pick any service area.
        //  And if the ticket/service area has multiple utility types, the same.
        //  Which means the user may pick a service area/utility type that does not match the current list item.
        //  And then we can't correcly set the Response Code that they picked in to the list or remove the item if the response is now complete.
        const haveServiceAreaNameColumn = displayedColumns.some(c => c.returnPropertyName === "ServiceArea_Name");

        if (singleItem && haveServiceAreaNameColumn) {
                //  Would be better if we had the IDs for these instead of the names, but this is what we have on the Service Area web user dashboards
                //  and didn't want to add more columns. Not even sure why we are showing the service area name here instead of code...
                //  If we ever change that to code, will break this.
                data.LimitToServiceAreaName = listItem["ServiceArea_Name"];

                //  Note that on the service area web user dashboard, this column is dynamic bases on whether or not any of the users service areas
                //  use Response by Utility Type.
                if (displayedColumns.some(c => c.returnPropertyName === "UtilityType_Name")) {
                    const utilityTypeName = listItem["UtilityType_Name"];
                    if (utilityTypeName && utilityTypeName !== "")
                        data.LimitToUtilityTypeName = utilityTypeName;
                }
        }

        //  This is called after a response has been saved.  Which we use to update the current response in the list
        //  if it is currently being displayed.
        data.OnResponseSaved = (savedServiceAreaInfo) => {
            //  If the list contains a service area code name, then we also limited the "add response" dialog to that name.  So we can also check to see
            //  if the list contains a column for the Response or if it is filtering on "HaveCompletedResponse" (and we just entered a complete response).
            if (haveServiceAreaNameColumn) {
                //  If the list also has the UtilityType, that has also been limited.  So it should be safe to just use the first item in CurrentResponses.
                const savedResponse = (savedServiceAreaInfo.CurrentResponses && savedServiceAreaInfo.CurrentResponses.length > 0) ? savedServiceAreaInfo.CurrentResponses[0] : null;
                if (savedResponse) {
                    if (displayedColumns.some(c => c.returnPropertyName === "Response_Code")) {
                        listItems.forEach(item => {
                            const serviceAreaName = item["ServiceArea_Name"];
                            let matches = serviceAreaName === savedServiceAreaInfo.Name;
                            if (matches) {
                                if (displayedColumns.some(c => c.returnPropertyName === "UtilityType_Name"))
                                    matches = item["UtilityType_Name"] === savedServiceAreaInfo.CurrentResponses[0].UtilityTypeName;

                                if (matches) {
                                    item["Response_Code"] = savedResponse.ResponseCode;
                                    if (this._DeviceDetectorService.IsPhone)
                                        item["Response_Name"] = savedResponse.ResponseDescription;
                                }
                            }
                            //  If the response is complete and we're filtering on HaveCompletedResponse, remove it from the list.
                            //  This function will refresh the list if necessary but allows a number of items to be removed without refreshing to
                            //  avoid spamming the server.
                            //  Not sure how, but there seem to be times when SearchFilter is not defined yet - maybe on the ticket list or if column not initialized yet?
                            if (!savedResponse.ResponseIsNotComplete && deleteRowsForCompletedResponses)
                                onDeleteItem(item);
                        });
                    }
                }
            }
        };

        if (this._DeviceDetectorService.IsPhone)
            this._SideSlideoutService.AttachComponent("Right", AddPositiveResponseFlyoutComponent, data)
                .OnClose
                .subscribe(() => { onClearSelected(); });
        else {
            this._Dialog.open(AddPositiveResponseDialog, {
                data: data,
                minWidth: '45%',
                width: '550px',
                maxWidth: '550px'
            }).afterClosed()
              .subscribe(() => { onClearSelected(); });
        }
    }

    private ViewTicketAndStartTicketFunction(ticketNumber: string, ticketFunction: TicketFunction): void {
        //  Navigates to the view ticket page and passes the ticketFunction in the "state".  The TicketRouteResolver can then read it
        //  and use it to start the ticket edit dialog.  Done this way (as opposed to creating a dedicated route) so that the
        //  URL is still the "view ticket" URL so refreshing does not start it again.  Do not want that to happen in case the
        //  user already did it and saved the edit.
        this._Router.navigate(["/tickets/view/" + ticketNumber], { state: { 'TicketFunction': ticketFunction } });
    }

    /**
     * Dynamically finds a Dialog Component and returns the type if it finds it.  To register a component,
     * decorate it with @ComponentLookup("key") with the key formatted as [occCode]-TicketFunction-[ticket function name]
     * @param ticketFunction
     */
    private GetTicketFunctionContentViewComponent(ticketFunction: TicketFunction): Type<ITicketFunctionContentView> {
        const componentKey = this._SettingsService.CurrentOneCallCenterCode + "-TicketFunctionContentView-" + ticketFunction.Name;
        const classRef = ComponentLookupRegistry.get(componentKey);
        return classRef;
    }

    //  TODO: This is old - remove this
    /**
     * Dynamically finds a Dialog Component and returns the type if it finds it.  To register a component,
     * decorate it with @ComponentLookup("key") with the key formatted as [occCode]-TicketFunction-[ticket function name]
     * @param ticketFunction
     */
    private GetTicketFunctionDialogComponent(ticketFunction: TicketFunction): any {
        const componentKey = this._SettingsService.CurrentOneCallCenterCode + "-TicketFunction-" + ticketFunction.Name;
        const classRef = ComponentLookupRegistry.get(componentKey);
        return classRef;
    }

    private StartTicketFunctionUsingContentView(contentViewComponent: Type<ITicketFunctionContentView>, ticketNumber: string, ticketFunction: TicketFunction,
        fromTicketDetails: boolean, onTicketSaved: () => void, ticketEntryForm: TicketEntryFormGroup): void {
        //  Don't allow if already busy - can happen if the network is really slow and user repeatedly clicks on the button!
        if (this._FormIsBusy)
            return;
        this._FormIsBusy = true;

        this._TicketService.StartTicketEdit(ticketNumber, ticketFunction.ID)
            .subscribe(response => {
                //  End this now (don't wait until dialog closes) because we don't really know what the dialog is going to do.
                //  For all we know, it could call back in to something here to save that is going to re-check _FormIsBusy...
                //  The dialog is opening so that should block the user from clicking on something else (or if they click outside, will
                //  cause dialog to immediately close and they will have to start over).
                this._FormIsBusy = false;

                if (!response?.StartEdit)
                    return;     //  Dig Site Rules were an Error or user chose to not continue

                const data = new StartTicketFunctionData(contentViewComponent, response, fromTicketDetails, onTicketSaved, ticketEntryForm);

                if (this._DeviceDetectorService.IsPhone) {
                    this._SideSlideoutService.AttachComponent("Right", TicketFunctionFlyoutComponent, data)
                        .OnClose.pipe(take(1))
                        .subscribe((saved: boolean) => {
                            //  If !saved, the user canceled/ESC'd the dialog.  So need to unlock the ticket.
                            if (!saved)
                                this._TicketService.AbandonTicketEdit(response.Ticket.ID);
                        });
                } else {
                    this._Dialog
                        .open(TicketFunctionDialogComponent, { data: data })
                        .afterClosed().pipe(take(1)).subscribe((saved: boolean) => {
                            //  If !saved, the user canceled/ESC'd the dialog.  So need to unlock the ticket.
                            if (!saved)
                                this._TicketService.AbandonTicketEdit(response.Ticket.ID);
                        });
                }
            }, () => this._FormIsBusy = false);
    }

    //  TODO: This is old - remove this
    private OpenTicketFunctionDialog(classRef: any, ticketNumber: string, ticketFunction: TicketFunction,
        fromTicketDetails: boolean, onTicketSaved: () => void, ticketEntryForm: TicketEntryFormGroup): void
    {
        //  Don't allow if already busy - can happen if the network is really slow and user repeatedly clicks on the button!
        if (this._FormIsBusy)
            return;
        this._FormIsBusy = true;

        this._TicketService.StartTicketEdit(ticketNumber, ticketFunction.ID)
            .subscribe(response => {
                //  End this now (don't wait until dialog closes) because we don't really know what the dialog is going to do.
                //  For all we know, it could call back in to something here to save that is going to re-check _FormIsBusy...
                //  The dialog is opening so that should block the user from clicking on something else (or if they click outside, will
                //  cause dialog to immediately close and they will have to start over).
                this._FormIsBusy = false;

                if (!response?.StartEdit)
                    return;     //  Dig Site Rules were an Error or user chose to not continue

                this._Dialog
                    .open(classRef, {
                        data: new TicketFunctionDialogData(response, fromTicketDetails, onTicketSaved, ticketEntryForm),
                        //  If this creates a smaller dialog than needed, add width to the content and the dialog will expand to fit
                    }).afterClosed().pipe(take(1)).subscribe(dialogResult => {
                        //  If false or undefined returned, the user canceled/ESC'd the dialog.  So need to unlock the ticket.
                        if (!dialogResult)
                            this._TicketService.AbandonTicketEdit(response.Ticket.ID);
                    });
            }, () => this._FormIsBusy = false);
    }

    public ConfirmCancelTicket(ticketNumber: string, ticketFunction: TicketFunction): void {
        //  Don't allow if already busy - can happen if the network is really slow and user repeatedly clicks on the button!
        if (this._FormIsBusy)
            return;
        this._FormIsBusy = true;

        //  Start a ticket edit so that we immediately do all of the checks to see if we can (still) do the cancel - because
        //  that could change if the user was sitting on the page for a while.  Also locks the ticket so another user can't
        //  start an edit while we are canceling.
        this._TicketService.StartTicketEdit(ticketNumber, ticketFunction.ID)
            .subscribe(response => {
                var cancelTicketData = new TicketCancelData(ticketNumber);

                if (this._DeviceDetectorService.IsPhone) {
                    this._SideSlideoutService.AttachComponent("Right", TicketCancelFlyoutComponent, cancelTicketData)
                        .OnClose.pipe(take(1))
                        .subscribe((data: TicketCancelData) => {
                            this.CancelTicketAndShowConfirmationDialog(data, response.Ticket.ID)
                        });
                }
                else {
                    this._Dialog
                        .open(TicketCancelDialogComponent, {
                            data: cancelTicketData,
                            minWidth: "55em",       // Needed to make the textarea row/col limit for DigSafe (75 chars/per row - all "A"'s need this width)
                        })
                        .afterClosed()
                        .subscribe(data => {
                            this.CancelTicketAndShowConfirmationDialog(data, response.Ticket.ID)
                        });
                }
            }, () => this._FormIsBusy = false);
    }

    private CancelTicketAndShowConfirmationDialog(data: TicketCancelData, ticketID: string): void {
        if (data) {
            this.CallCancelTicket(data.TicketNumber, data.Reason)
                .subscribe(cancelResponse => {
                    this._FormIsBusy = false;
                    if (cancelResponse) {
                        //  Shows "ticket canceled" message and allows sending a copy to the excavator
                        this._Dialog.open(TicketCanceledConfirmationDialogComponent, { data: cancelResponse.Ticket });
                    }
                }, () => this._FormIsBusy = false);
        } else {
            this._FormIsBusy = false;
            this._TicketService.AbandonTicketEdit(ticketID);
        }
    }

    private CallCancelTicket(ticketNumber: string, reason: string): Observable<TicketEntryResponse> {
        if (!ticketNumber || !reason)
            return of(null);

        return new Observable<TicketEntryResponse>(observer => {
            const request = new CancelTicketRequest(ticketNumber, reason, this._DeviceDetectorService.DeviceFormFactor);

            this._HttpClient.post<TicketEntryResponse>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/CancelTicket", request).subscribe({
                next: response => {
                    this._TicketService.OnTicketEntryResponseReceived(response);
                    this._Router.navigate(["/tickets/view/" + response.Ticket.ID]);
                    observer.next(response);
                    observer.complete();
                },
                error: () => {
                    observer.next(null);
                    observer.complete();
                }
            });
        });
    }

    public EditOrResumeTicket(ticketNumber: string): void {
        this._Router.navigate(["/tickets/edit/" + ticketNumber]);
    }

    public CopyTicket(ticketNumber: string): void {
        this._Router.navigate(["/tickets/copy/" + ticketNumber]);
    }

    public ConfirmReleaseSuspendedTicket(ticket: Ticket): void {
        //  This dialog calls the ReleaseSuspendedTicket api directly because it then also needs to call SendTicket (to send excavator email).
        //  And they need to be called in sequence with SendTicket only being called if the ticket is successfully completed.
        this._Dialog
            .open(ConfirmReleaseSuspendedTicketDialogComponent, {
                data: {
                    Ticket: ticket
                },
                disableClose: true
            }).afterClosed().subscribe((val) => {
                if (val) {
                    //  Suspended ticket was successfully completed
                    this._Router.navigate(["/tickets/view/" + ticket.ID]);
                }
            });
    }

    public ConfirmVoidTicket(ticketID: string): void {
        this._Dialog.open(ConfirmVoidTicketDialogComponent).afterClosed()
            .subscribe((result: ConfirmVoidTicketDialogResponse) => {
                if (result?.Discard) {
                    const request = new VoidTicketRequest(ticketID, result.SendVoidedNotification);
                    this._HttpClient.post<TicketEntryResponse>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/VoidTicket", request)
                        .subscribe(response => {
                            if (response) {
                                this._TicketService.OnTicketEntryResponseReceived(response);
                                this._Router.navigate(["/tickets/view/" + response.Ticket.ID]);
                            }
                        });
                }
            });
    }

    public UnlockTicket(ticketID: string): Observable<TicketEntryAllowedTicketActions> {
        return this._HttpClient.put<TicketEntryAllowedTicketActions>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/UnlockTicket/" + ticketID, null);
    }

    public QATicket(ticketID: string): Observable<any> {
        return this._HttpClient.put<any>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/QATicket/" + ticketID, null);
    }

    public SetQAFlaggedForReview(ticketID: string, flaggedForReview: boolean): Observable<any> {
        return this._HttpClient.put<any>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/QAFlagForReview/" + ticketID + "/" + flaggedForReview, null);
    }

    public MarkWorkCompleted(ticketIDs: string[], workCompleted: boolean): Observable<TicketEntryAllowedTicketActions> {
        const disclaimerObs = workCompleted ? this._DisclaimerService.DisclaimerForType(DisclaimerTypeEnum.MarkedWorkComplete) : of(null);

        return disclaimerObs.pipe(take(1),
            mergeMap(disclaimer => {
                //  If a disclaimer is configured, show it.  The output should be true if user confirms
                if (!disclaimer)
                    return of(true);
                return this._Dialog
                    .open(ConfirmationDialogComponent, {
                        data: new DialogModel("Confirm Work Complete", disclaimer)
                    }).afterClosed().pipe(take(1), map(val => val));
            }),
            mergeMap(confirmed => {
                if (!confirmed)
                    return of(null);

                const request: MarkCompletedRequest = new MarkCompletedRequest(workCompleted, ticketIDs);
                return this._HttpClient.post<TicketEntryAllowedTicketActions>(this._SettingsService.ApiBaseUrl + "/Tickets/Entry/MarkComplete/", request);
            }),
            mergeMap(allowedActions => {
                this._TicketService.SetAllowedActions(allowedActions);
                return of(allowedActions);
            })
        );
    }

    //  This is called when editing a ticket (by enumerating over the dropdown items for the Status field).
    //  These always go through the full save process (with Incomplete & Void skipping some things but still calling the normal SaveTicket)
    public SaveWithStatus(ticketEntryForm: TicketEntryFormGroup, status: TicketStatusEnum): void {
        //  This keeps the Save links disabled while we process the action.  Some of them (or at least
        //  saving - and fetching affected service areas) may take longer than the double-click guard.
        //  This keeps the user from being able to click the link again which can cause us to create
        //  multiple affected service area dialogs!
        if (this._FormIsBusy)
            return;
        this._FormIsBusy = true;

        switch (status) {
            case TicketStatusEnum.Released:
                this.TriggerSaveWithStatus(ticketEntryForm, status);     //  No confirmation prompt needed - the complete process handles all that
                break;
            case TicketStatusEnum.Incomplete: {
                const incompleteMessage = this._SettingsService.IncompleteWarningMessage();
                this.ShowConfirmStatusDialog(ticketEntryForm, "Incomplete Ticket?", incompleteMessage, "Incomplete",
                    "Would you still like to save this as an Incomplete Ticket?", status);
                break;
            }
            case TicketStatusEnum.Suspended:
                this.ShowConfirmStatusDialog(ticketEntryForm, "Suspend Ticket?",
                    "<span style='color:red'>This will save the Ticket but not send it to the affected Service Areas until it has been reviewed by the One Call Center.</span>",
                    "Suspend", "Would you still like to save this as a Suspended Ticket?", status);
                break;
            case TicketStatusEnum.Void:
                this.ShowConfirmVoidTicket(ticketEntryForm);
                break;
        }
    }

    private ShowConfirmStatusDialog(ticketEntryForm: TicketEntryFormGroup, title: string, message: string, actionText: string,
                                    confirmText: string, status: TicketStatusEnum): void {
        this._Dialog
            .open(ConfirmationDialogComponent, {
                data: new DialogModel(title, message, actionText, confirmText),
                disableClose: true
            }).afterClosed().subscribe((val) => {
                if (val)
                    this.TriggerSaveWithStatus(ticketEntryForm, status);
                else
                    this._FormIsBusy = false;
            });
    }

    private ShowConfirmVoidTicket(ticketEntryForm: TicketEntryFormGroup): void {
        this._Dialog.open(ConfirmVoidTicketDialogComponent).afterClosed()
            .subscribe((result: ConfirmVoidTicketDialogResponse) => {
                if (result?.Discard) {
                    ticketEntryForm.get("Status").setValue(TicketStatusEnum.Void);
                    //  TicketEntryFormBase.OnTicketAction() expects an object here with Reason and SendVoidedNotification properties
                    this._TicketService.TriggerAction(TicketActionEnum.SaveIncompleteOrVoid, result, () => this.ClearFormBusy());
                }
                else
                    this._FormIsBusy = false;
            });
    }

    private TriggerSaveWithStatus(ticketEntryForm: TicketEntryFormGroup, status: TicketStatusEnum): void {
        ticketEntryForm.get("Status").setValue(status);
        this._TicketService.TriggerAction(TicketActionEnum.SaveTicket, null, () => this.ClearFormBusy());
    }

    //  Called after we have triggered TicketService.TriggerAction and the action has been processed enough
    //  to either have completed or shown a dialog.  Used to know when it's ok to re-enable the links on the form
    //  to prevent the user from being able to trigger them multiple times.  Even though there is a double click
    //  guard on them, if we show a dialog (such as the affected service area dialog) that can take more than the
    //  timeout used by the click guard, the user can (and *DOES*) click on the link again.  That can cause us
    //  to create multiple affected service area dialogs.  Which then allows the user to save the same ticket
    //  multiple times.
    public ClearFormBusy(): void {
        this._FormIsBusy = false;
    }

    public DiscardTicket(): void {
        //  Go "back" to the previous viewable ticket.  We track that in TicketService when we initiate the different routes
        //  that could be used as a "viewable" Ticket.ID or Ticket.Number.  The navigation will trigger the route guard that
        //  will prompt the user about discarding changes.  If user confirms, they will be navigated to this ticket.
        //  We can just do "window.history.go(-1)" because if the user did something like a new ticket followed by
        //  "create another", going back will leave them on "new" again.  In that situation, this method will leave them on
        //  the ticket that was last saved.
        //window.history.go(-1);

        if (this._TicketService.LastViewableTicketIDorNumber)
            this._Router.navigate(["/tickets/view/" + this._TicketService.LastViewableTicketIDorNumber])
        else
            this._Router.navigate(["/tickets"]);        //  If no viewable last ticket, will go to default ticket page
    }

    public static LockedByMessage(allowedActions: TicketEntryAllowedTicketActions): string {
        if (!allowedActions.LockedByAnotherUser)
            return null;

        if (allowedActions.LockedByUserName && allowedActions.LockedByUserName !== "")
            return "* LOCKED BY " + allowedActions.LockedByUserName + " *";

        return "* LOCKED *";
    }
}
