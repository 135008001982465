import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldUIControlTypeEnum } from 'Enums/FieldUIControlType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import FileSaver from 'file-saver';
import { NameValueUpdateRequest } from 'Models/Base/NameValueUpdateRequest.model';
import { PagedListResponse } from 'Models/Base/PagedListResponse.model';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { ICRUD } from 'Models/Interfaces/ICRUD.interface';
import { IEntity } from 'Models/Interfaces/IEntity.interface';
import { CountyAutocompleteRequest } from 'Models/Maps/CountyAutocompleteRequest.model';
import { CountyAutocompleteResponse } from 'Models/Maps/CountyAutocompleteResponse.model';
import { PlaceAutocompleteRequest } from 'Models/Maps/PlaceAutocompleteRequest.model';
import { PlaceAutocompleteResponse } from 'Models/Maps/PlaceAutocompleteResponse.model';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchColumnResponse } from 'Models/Searching/SearchColumnResponse.model';
import { SearchFilter } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { SearchResponse } from 'Models/Searching/SearchResponse.model';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { PermissionsService } from 'Services/PermissionsService';
import { SettingsService } from 'Services/SettingsService';
import { Guid } from 'Shared/Utils/Guid';
import { UIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';

export class BulkEntityActionRequest {
    //IDs to take action on
    IDs: number[] | string[];

    //If true, take action on all items in the system and ignore the IDs property
    //AllInFilter: boolean;

    //If true, then take action on all the items EXCEPT the ones in the IDs property
    Exclude: boolean;

    //Required doing "All" because we don't want to easliy allow them to remove all
    Filters: SearchFilter[];

    //Helps to do things in order if they select a number above the max that we allow.
    OrderBy: SearchOrderBy[];

    constructor() {

    }
}

export class BulkJoinActionRequest {
    //IDs to take action on
    Items: any[] = new Array<any>();

    //If true, take action on all items in the system and ignore the IDs property
    All: boolean;

    //If true, then take action on all the items EXCEPT the ones in the IDs property
    Exclude: boolean;

    PropertyID: string;
    PropertyName: string;

    constructor() {

    }
}

export class UpdateSuccess {
    constructor(public request: NameValueUpdateRequest, public response: IEntity | any = null) {

    }
}

/*
 *  This is the services needed for all of the CRUD base stuff.
 *  This way if we need to add or remove a new service we don't have to update all the services that extend this base service
 */
@Injectable({ providedIn: 'root' })
export class CRUDServices {
    constructor(public http: HttpClient, public settingsService: SettingsService, public permissionService: PermissionsService, public toastrService: ToastrService) { }
}

export abstract class CRUDBaseService<T extends IEntity> implements ICRUD<T> {
    public IDsStale: boolean = false;
    public NextID: string;
    public PreviousID: string;
    public SelectedPosition: number;
    public LastSearchRequest: SearchRequest;
    private IDsFromLastSearch: string[] = [];
    private IDForNextPage: string;
    private IDForPreviousPage: string;
    private highestPageLoaded: number;
    private lowestPageLoaded: number;

    public EntityID: string;//Needed for the ID to do saves.  Of we really need it out of here then we need to make an observable
    protected abstract apiPath: string;

    //  Page size is stashed here so that it's remembered if we leave the search page and come back
    public LastPageSize: number = 25;

    //These are public so that if they need to be overridden they can.  i.e. when a child item inherits from a parent like an Address inherits from whatever it's parent is
    abstract ViewPermission: PermissionsEnum;//Needs to be overriden and set to the proper permission
    abstract EditPermission: PermissionsEnum;//Needs to be overriden and set to the proper permission
    abstract CreatePermission: PermissionsEnum;//Needs to be overriden and set to the proper permission
    abstract DeletePermission: PermissionsEnum;//Needs to be overriden and set to the proper permission
    CopyPermission: PermissionsEnum;//Needs to be overriden and set to the proper permission,  but not abstract because we may not want it on all entities

    constructor(protected services: CRUDServices) { }

    CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', itemID: string = null, propertyName: string = null): Observable<boolean> {
        switch (action) {
            case 'View':
                //If the ID is null then it's probably a list search (may need to make that it's own action), in that case
                //  the server needs to figure out what the person is allowed to see
                if (!itemID)
                    return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, null, true);

                return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, [itemID]);
            case 'Edit':
                return this.services.permissionService.CurrentUserHasPermission(this.EditPermission, [itemID]);
            case 'Create':
                return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, [itemID]);
            case 'Delete':
                return this.services.permissionService.CurrentUserHasPermission(this.DeletePermission, [itemID]);
            default:
                return of(false);
        }
    }

    public ClearAllNextAndPreviousValues() {
        this.SetIDListForNextPrevious(null);
        this.SetNextAndPreviousIDs(null);
    }
    protected SetIDListForNextPrevious(items: any[], clearExisting: boolean = true, addBegining: boolean = false) {

        if (!items)
            this.IDsFromLastSearch = [];
        else if (clearExisting)
            this.IDsFromLastSearch = items.map(i => i.ID);
        else if (addBegining) {
            let foundNextItem = false;

            //Go in reverse
            for (let i = items.length - 1; i >= 0; i--) {
                if (!foundNextItem)
                    foundNextItem = !this.IDForPreviousPage || this.IDForPreviousPage === items[i].ID;

                if (foundNextItem)
                    this.IDsFromLastSearch.unshift(items[i].ID);
            }


            this.IDsStale = this.IDForPreviousPage !== items[items.length - 1].ID;
            if (!foundNextItem) {
                this.PreviousID = null;
                //this.IDsStale = true;
                this.IDForPreviousPage = null;
            }
        }
        else {//Keep the past so we can go backwards and not worry about calling the server to do paging.
            let foundNextItem = false;

            for (let i = 0; i < items.length; i++) {
                if (!foundNextItem)
                    foundNextItem = !this.IDForNextPage || this.IDForNextPage === items[i].ID;

                if (foundNextItem)
                    this.IDsFromLastSearch.push(items[i].ID);
            }

            this.IDsStale = this.IDForNextPage !== items[0].ID;
            if (!foundNextItem) {
                this.NextID = null;
                //this.IDsStale = true;
                this.IDForNextPage = null;
            }
        }
    }

    protected SetNextAndPreviousIDs(id: string) {
        if (!id) {
            this.NextID = null;
            this.PreviousID = null;
            this.highestPageLoaded = null;
            this.lowestPageLoaded = null;
            this.PreviousID = null;
            this.NextID = null;
            this.IDsStale = false;
            this.SelectedPosition = null;
        }
        else {
            let lastFetchedID = false;
            let firstfetchedID = false;//If we are showing the begining of the list of fetched items.  May need to get previous page
            for (let i = 0; i < this.IDsFromLastSearch.length; i++) {
                if (this.IDsFromLastSearch[i] === id) {

                    if (this.lowestPageLoaded === 1)
                        this.SelectedPosition = i + 1;//Need to add one so it's displayed right.  i.e. should be displayed as 1, not 0 for the first one
                    else
                        this.SelectedPosition = ((this.lowestPageLoaded - 1) * this.LastSearchRequest.PageSize) + (i + 1);

                    firstfetchedID = i === 0;

                    this.PreviousID = this.IDsFromLastSearch[i - 1];

                    if ((i + 1) <= this.IDsFromLastSearch.length)
                        this.NextID = this.IDsFromLastSearch[i + 1]

                    lastFetchedID = (i + 1) >= this.IDsFromLastSearch.length;
                }
            }

            if (firstfetchedID && this.IDForPreviousPage) {
                if (!this.PreviousID && id !== this.IDForPreviousPage)
                    this.PreviousID = this.IDForPreviousPage;

                this.LastSearchRequest.PageNum = this.lowestPageLoaded - 1;
                this.searchCall(this.LastSearchRequest)
                    .subscribe(val => {
                        this.lowestPageLoaded--;
                        this.SetIDListForNextPrevious(val.Items, false, true);
                        this.IDForPreviousPage = val.PreviousPageLastID;
                    });
            }

            if (lastFetchedID && this.IDForNextPage) {//IDForNextPage will be null on the last page.  No need to get anymore if there isn't any to get
                if (!this.NextID && id !== this.IDForNextPage)
                    this.NextID = this.IDForNextPage;

                this.LastSearchRequest.PageNum = this.highestPageLoaded + 1;
                this.searchCall(this.LastSearchRequest)
                    .subscribe(val => {
                        this.highestPageLoaded++;
                        this.SetIDListForNextPrevious(val.Items, false);
                        this.IDForNextPage = val.NextPageFirstID;
                    });
            }
        }
    }

    private _LastHttpRequest: Subscription = null;

    private searchCall(searchRequest: SearchRequest): Observable<SearchResponse> {
        //  If we have a request pending, cancel it.  Otherwise, a search request that takes a long time, can get spammed
        //  to the server very easily (by the user clicking/changing filters quickly).  That can overload the database
        //  server and exhaust all available DB connections!  The server detects this and will cancel the executing query.
        if (this._LastHttpRequest)
            this._LastHttpRequest.unsubscribe();

        return new Observable<SearchResponse>(observer => {
            this._LastHttpRequest = this.services.http.post<SearchResponse>(this.SearchUrl(), searchRequest)
                .subscribe(val => {
                    this._LastHttpRequest = null;
                    observer.next(val);
                    observer.complete();
                }, err => {
                    this._LastHttpRequest = null;
                    observer.error(err);
                    observer.complete();
                });
            return this._LastHttpRequest;
        });
    }

    protected SearchUrl(): string {
        return this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/search";
    }

    public ExportList(searchRequest: SearchRequest, defaultFileName: string = null) {//: Observable<Stream> {
        this.CanPerformAction('View').pipe(mergeMap(allowed => {
            if (!allowed)//If they don't have permission then return an empty list, else do the search
            {
                this.services.toastrService.error("You don't have permissions to view the item to get the .csv file");
                return of();    //  Exit now or execution will continue and still execute the api call!
            }

            return this.services.http.post(this.ExportSearchUrl(), searchRequest, { observe: "response", responseType: "blob" });
        })).subscribe((response: any) => {
            if (!response)
                return;

            if (!defaultFileName) {
                const contentDispositionHeader = response.headers.get("Content-Disposition");
                if (contentDispositionHeader)
                    defaultFileName = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '');
                if (!defaultFileName)
                    defaultFileName = "export.csv";
            }
            if (!defaultFileName.endsWith(".csv"))
                defaultFileName += ".csv";

            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response.body);

            FileSaver.saveAs(new Blob(binaryData, { type: dataType }), defaultFileName);
        }, (err: HttpErrorResponse) => {
            if (err.status === 404)
                this.services.toastrService.warning("Your search returned 0 rows.");
            else
                this.services.toastrService.error("Export failed.  Please try again or contact the One Call Center for assistance.");
        });
    }

    protected ExportSearchUrl(): string {
        return this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/ExportSearch";
    }

    //  Do not call this for Autocompletes!
    //  Call SearchForAutocomplete() if you care about permission checks (service area users limited to their SA's)
    //  or call Autocomplete() if you want no server permission checks (which should NEVER actually be the case!).
    //  Yes, that's jacked - see comments above SearchForAutocomplete().
    public GetList(searchRequest: SearchRequest, keepListOfIDsForNextPreviousFunctionality: boolean = true): Observable<SearchResponse> {

        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            return new Observable<SearchResponse>(observer => {
                //Don't clear out if it's not keeping the list
                if (keepListOfIDsForNextPreviousFunctionality)
                    this.IDsFromLastSearch = [];

                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                    return;     //  Exit now or execution will continue and still execute the api call!
                }

                this.searchCall(searchRequest)
                    .subscribe(val => {
                        if (keepListOfIDsForNextPreviousFunctionality) {
                            this.SetIDListForNextPrevious(val.Items);
                            this.LastSearchRequest = searchRequest;

                            if (searchRequest.LoadColumnsAndFilters === true) {
                                //Need to set these so that they can use the "up/down" option on the details to get the next page properly
                                //  If for some reason the Server didn't return them then set the values to what was used for the call (probably null).
                                this.LastSearchRequest.Filters = val.Filters ? val.Filters.Filters : searchRequest.Filters;
                                this.LastSearchRequest.Columns = val.Columns ? val.Columns.Columns : searchRequest.Columns;
                                this.LastSearchRequest.OrderBy = val.Filters ? val.Filters.OrderBy : searchRequest.OrderBy;                            }

                            this.LastSearchRequest.LoadColumnsAndFilters = false;
                            this.IDForNextPage = val.NextPageFirstID;
                            this.IDForPreviousPage = val.PreviousPageLastID;
                            this.highestPageLoaded = searchRequest.PageNum;
                            this.lowestPageLoaded = searchRequest.PageNum;
                            this.IDsStale = false;
                        }

                        this.SearchResponseReceived(val);

                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                        return of<PagedListResponse<T>>();
                    });
            });
        }));
    }

    //  Can override this if need to modify a search response before it is returned to the UI component
    protected SearchResponseReceived(response: SearchResponse): void {
    }

    //  The GetList vs Autocomplete methods are both jacked up in different ways when it comes to using them
    //  for Autocomplete.
    //  1) Both of them enforce the "View" permission check before they will even issue the api call.  The problem there
    //     is that when doing an autocomplete, the user may not have "admin view" permission on the type of entity that
    //     is being searched.  This especially happens on the Ticket and Message search pages.  Local users
    //     do not have Admin permissions!  So that "View" permission check causes a local user to not even be able
    //     to search for searvice areas on the ticket list or manual call out dashboard pages.
    //  2) The EntityAutocomplete api does no permission checks what-so-ever.  So a Service Area user searching for
    //     service areas is not limited to only their service areas - they will see them all!
    //  3) The Search api does permission checks, but they are not necessarily correct from the point of view of
    //     an autocomplete.  An Excavator user filtering a ticket list by Service Areas should see *ALL* service areas
    //     in the autocomplete.  While a Service Area user filtering on Service Areas should see only the ones they
    //     are linked to.
    //  This 3rd method uses the Search api so that we at least get the basic permission checks in the query.  It will
    //  still suffer from #3, but it at least handles most cases.  It also does not enforce the "View" permission check
    //  at all and lets the server do those checks - so local users with no admin permissions now get results.
    //  ** We should clean all of this up at some point: Change the Autocomplete() biz/query to do the correct permission
    //  checks and don't do the "View" check.
    public SearchForAutocomplete(searchRequest: SearchRequest): Observable<SearchResponse> {
        return new Observable<SearchResponse>(observer => {
            this.searchCall(searchRequest)
                .subscribe(val => {
                    observer.next(val);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                    return of<PagedListResponse<T>>();
                });
        });
    }

    //Similar to GetList, but used for autocompletes.  That means we call a different API (so we can do different permissions) and we also don't do
    //  anything to try and keep track of the last used fitlers or anything
    Autocomplete(searchRequest: SearchRequest): Observable<SearchResponse> {

        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            return new Observable<SearchResponse>(observer => {
                
                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                    return;     //  Exit now or execution will continue and still execute the api call!
                }

                this.services.http.post<SearchResponse>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/EntityAutocomplete", searchRequest)
                    .subscribe(val => {
                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                        return of<PagedListResponse<T>>();
                    });
            });
        }));
    }

    SearchMoreData(id: string): Observable<any> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            return new Observable<SearchResponse>(observer => {

                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                    return;     //  Exit now or execution will continue and still execute the api call!
                }

                return this.services.http.get<any>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/SearchListMore/" + id)
                    .subscribe(val => {
                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                        return of();
                    });
            });

        }));
    }

    protected CheckMultipleRequestValid(exclude: boolean, filters: SearchFilter[]): boolean {

        if (exclude && !filters) {
            this.services.toastrService.error("Can't apply the action to all the items in the system.  You need to use a filter.");
            //Maybe return an empty observable so it doesn't log an error to the system?  Didn't do that because this will hopefully never happen
            return false;
        }

        return true;
    }
     
    DeleteMultiple(ids: number[] | string[], excludeIDs: boolean = false, filters: SearchFilter[] = null, orderBy: SearchOrderBy[] = null): Observable<boolean> {
        if (!this.CheckMultipleRequestValid(excludeIDs, filters))
            return of(false);

        return this.CanPerformAction('Delete').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<boolean>(false)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(50));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            const request = new BulkEntityActionRequest();
            request.IDs = ids;
            request.Exclude = excludeIDs;
            request.Filters = filters;
            request.OrderBy = orderBy;

            return this.services.http.put<boolean>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/DeleteMultiple", request);
        }));
    }

    Delete(id: number | string): Observable<T> {
        return this.CanPerformAction('Delete', id.toString()).pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<T>({} as T)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(50));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.delete<T>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/" + id);
        }));
    }

    Get(id: number | string): Observable<T> {
        return this.CanPerformAction('View', id.toString()).pipe(mergeMap(allowed => {
            return new Observable<T>(observer => {

                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                    return;     //  Exit now or execution will continue and still execute the api call!
                }
                return this.services.http.get<T>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/" + id)
                    .subscribe(val => {

                        this.SetNextAndPreviousIDs(val.ID);

                        observer.next(val);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                        return of<T>();
                    });
            });

        }));
    }

    //The caller needs to supply the subscribe so that it can do any logic that's needed
    InsertOrUpdate(model: any): Observable<T> {
        const actionObservable = model.ID && model.ID !== Guid.empty ? this.CanPerformAction('Edit') : this.CanPerformAction('Create');
        return actionObservable.pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<T>({} as T)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(50));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.post<T>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath, model);
        }));
    }


    AddCollectionToEntity(addToEntityID: string, propertyName: string, ids: string[], all: boolean = false, excludeIDs: boolean = false): Observable<any> | any {
        console.error("Need to implement Adding to an Entity in the inheriting service!");
        //
        //
        //  MAKE SURE TO ADD PERMISSION CHECKS
        //
        //
        //The entity ID is the entity to add the collection to.
        //  i.e if adding people to a role. The service is the person service, the entityID is the RoleID, the propertyName is the Role property on a Person, and the ids is the people ids to add
    }

    RemoveCollectionFromEntity(removeFromEntityID: string, propertyName: string, ids: string[], all: boolean = false, excludeIDs: boolean = false): Observable<any> | any {
        console.error("Need to implement Removing from an Entity in the inheriting service!");
        //
        //
        //  MAKE SURE TO ADD PERMISSION CHECKS
        //
        //
        //The entity ID is the entity to remove the collection from.
        //  i.e if removing people(collection) from a role.  The service is the person service, the entityID is the RoleID, the propertyName is the RoleID property on a Person, and the ids is the people ids to remove
    }

    //  TODO: This observable does not make any sense.  Every single place that calls SaveProperty() (or SaveChildEntity())
    //  subscribes to this observable first.  SaveProperty should just return an observable like everything else (and Angular) does!
    //  Having it disconnected like this makes it impossible to properly handle errors!!!  If there is an error, our dialogs currently
    //  get stuck with a disabled Save button so the only way to re-attempt is to close it and re-do whatever was done on the dialog.
    //  Change everything to call SavePropertyAsync!
    //An observable that will emit after an Update
    public updateObservable: Subject<UpdateSuccess> = new Subject();
    SaveProperty(propertyName: string, value: any, form: UntypedFormControl | UntypedFormGroup, returnUpdatedItem: boolean = false,
        onSuccess?: () => void, onError?: () => void): void
        {
        this.CanPerformAction('Edit', this.EntityID, propertyName).subscribe(allowed => {
            if (!allowed || !this.EntityID) {
                if (!allowed) {
                    this.services.toastrService.error("Permission Denied");
                }
                return;
            }

            const request = new NameValueUpdateRequest();
            request.ID = this.EntityID;
            request.Name = propertyName;
            request.Value = value;
            request.ReturnUpdatedItem = returnUpdatedItem;

            this.services.http.put<T>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + '/Property', request)
                .subscribe(
                    data => {
                        this.updateObservable.next(new UpdateSuccess(request, data));
                        //  Success!  Mark the field as pristine (not dirty).  If we got data back, the server updated something other than
                        //  just our single field so need to update the entire thing so that those changes are reflected in the UI too.

                        if (form)
                            form.markAsPristine();

                        //  The onSuccess & onError delegates are necessary because of the mess of the updateObservable and to make sure that
                        //  errors are handled correctly in the AutosavingFormControl.  Without doing this, that control tracks the previous value
                        //  but it ALWAYS updates it to the new value before calling this method.  And then it has no error tracking anywhere so
                        //  when something fails to save, it never rolls it back so the UI is left in a state that doesn't match the server!
                        if (onSuccess)
                            onSuccess();
                    },
                    err => {
                        if (onError)
                            onError();
                    }
                );
        })
        
    }

    //If you are just updating an ID property then you just need to pass in the value and not the model.  If you're inserting a new model (i.e. adding an address to the excavator company) then don't pass in the value, only pass in the model
    SaveChildEntity(propertyName: string, value: string, model: IEntity | any, form: UntypedFormControl | UntypedFormGroup): void {
        this.SaveProperty(propertyName, (value !== null && value !== undefined) ? value : model, form);
    }

    //  entityID passed in here instead of using this.EntityID because there are lots of spots that specifically set it BEFORE
    //  calling SaveProperty and then have special logic to unset it "just in case".  So if there's a danger with the EntityID being
    //  accidentally re-used, why set it like that to begin with?...Because the autosave control doesn't have a reference to the ID so it cames from the service, and the service is a single instance so the ID could carry over if it's injected and not reset
    //  It also prevents issues with something "forgetting" to set it before calling this method.
    public SavePropertyAsync(entityID: string, propertyName: string, value: any, form: UntypedFormControl | UntypedFormGroup, returnUpdatedItem: boolean = false): Observable<T> {
        return this.CanPerformAction('Edit', entityID, propertyName).pipe(mergeMap(allowed => {
            return new Observable<T>(observer => {
                if (!allowed)
                {
                    this.services.toastrService.error("Permission Denied");
                    observer.error("Permission Denied");
                    observer.complete();
                    return;
                }

                const request = new NameValueUpdateRequest();
                request.ID = entityID;
                request.Name = propertyName;
                request.Value = value;
                request.ReturnUpdatedItem = returnUpdatedItem;

                this.services.http.put<T>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + '/Property', request)
                    .subscribe(data => {
                        //  Success!  Mark the field as pristine (not dirty).  If we got data back, the server updated something other than
                        //  just our single field so need to update the entire thing so that those changes are reflected in the UI too.
                        if (form)
                            form.markAsPristine();

                        observer.next(data);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                    });
            });
        }));
    }

    private _SearchColumns: SearchColumn[] = null;
    private _FilterColumns: SearchColumn[] = null;

    //  Tracked so that we know to refresh if we switched One Calls.
    private _LastOneCallCenterCode: string = null;

    protected ClearCachedSearchColumnInfo(): void {
        this._SearchColumns = null;
        this._FilterColumns = null;
        this._LastOneCallCenterCode = null;
    }

    protected HasSearchColumnsEndpoint: boolean = false;

    public GetAvailableSearchColumnsAndFilters(): Observable<{ columns: SearchColumn[], filters: SearchColumn[] }> {
        if (this._SearchColumns && this._LastOneCallCenterCode === this.services.settingsService.CurrentOneCallCenterCode)
            return of({ columns: this._SearchColumns, filters: this._FilterColumns });

        //  TODO: Currently the "SearchColumns" api only exists on 3 entities - Tickets, TicketResponses and Messages.
        //  At some point, we need to make our other lists user configurable so all entities with the "Search" api
        //  will also need the SearchColumns endpoint.
        //  For the moment, we require the derived class to opt-in using this property.
        if (!this.HasSearchColumnsEndpoint)
            return of(null);

        //  Don't have data cached yet - fetch it now
        return new Observable<{ columns: SearchColumn[], filters: SearchColumn[] }>(observer => {
            this.services.http.get<SearchColumnResponse[]>(this.SearchColumnsUrl())
                .subscribe(data => {
                    let searchCols: SearchColumn[] = [];
                    if (data)
                        data.forEach(val => searchCols.push(this.AddUIFunctionsToSearchColumn(val)));

                    let filterCols = this.CustomizeSearchFilters(searchCols.map(x => Object.assign({}, x)));

                    //  Don't sort these lists!  They are used (at least in the case of the reports QueryViewer) to determine the displayed
                    //  columns and the order needs to be preserved.  Could not find any other references to these columns or reason for why
                    //  they would be sorted...
                    //  BaseListDisplayPage will sort them once it has finished building the display column list (from the unsorted list).
                    //searchCols = searchCols.sort((a, b) => a.name.localeCompare(b.name));
                    //filterCols = filterCols.sort((a, b) => a.name.localeCompare(b.name));

                    this._SearchColumns = searchCols;
                    this._FilterColumns = filterCols;

                    //  So we know if we have values cached for a different One Call - switching One Calls does not clear these
                    //  values since they may be stored in multiple CRUD services.
                    this._LastOneCallCenterCode = this.services.settingsService.CurrentOneCallCenterCode;
                    
                    observer.next({ columns: searchCols, filters: filterCols });
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    protected SearchColumnsUrl(): string {
        return this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/SearchColumns";
    }

    //  Derived class can override this to add additional filters.
    protected CustomizeSearchFilters(filterColumns: SearchColumn[]): SearchColumn[] {
        return filterColumns;
    }

    //  Override this to add custom handling for SearchColumns specific to this entity.
    //  And always call this (super) method first!
    protected AddUIFunctionsToSearchColumn(response: SearchColumnResponse): SearchColumn {

        const col = new SearchColumn(response.PropertyName.replace(/\./gi, "_"), response.DisplayName, response.PropertyName, response.FilterName);

        //  CanSort & CanFilter will cause the sorting or filtering to be hidden independently.
        //  If both are false, col.canSearchAndFilter will be false and cause clicking on the column header to do nothing.
        if (!response.CanSort)
            col.CanSort = false;
        if (!response.CanFilter)
            col.CanFilter = false;

        if (response.FixedValues) {
            const options: SelectOption[] = [];
            for (const key in response.FixedValues)
                options.push(new SelectOption(response.FixedValues[key], key));

            col.filterOptions = of(options);
        }

        col.OtherFilterColumnNames = response.OtherFilterColumnNames ? response.OtherFilterColumnNames : [];

        if (response.UIControlType) {
            switch (response.UIControlType) {
                case FieldUIControlTypeEnum.Integer:
                    col.useNumberSearch = true;
                    break;
                case FieldUIControlTypeEnum.Phone:
                    col.formatType = 'phone';
                    break;
                case FieldUIControlTypeEnum.Date:
                case FieldUIControlTypeEnum.DateTime:
                    col.formatType = 'date';
                    col.useDateSearch = true;
                    col.ShowFutureDateOptions = (col.column !== "TakenStartDate") && (col.column !== "TakenEndDate") && (col.column !== "CreateDate");   //  All others could be in future
                    col.format = UIDateTimeFormat;
                    break;
            }
        }

        //  Keep the server provided UIControlType if there is one!  We shouldn't need 27 properties stored in the SearchColumn for this!
        //  If we have a UIControlType, we should be using that to drive the UI Component used to display & filter on the column!
        col.UIControlType = response.UIControlType;

        return col;
    }

    protected ConfigureCountySearchColumn(col: SearchColumn): void {
        col.filterOperator = SearchFilterOperatorEnum.Equals;   //  *NOT* contains - we are selecting exact value from autocomplete!  Contains will do "like" search which is very inefficient
        col.autocompleteResultFilterValue = "Name";
        col.autoComplete = true;
        col.autoCompleteSearchFunction = (filter: SearchRequest) => {
            const searchValue = filter.Filters[0].Values[0].FilterValue;
            const request = new CountyAutocompleteRequest(null, searchValue, null);

            return this.services.http.post(this.services.settingsService.ApiBaseUrl + "/Maps/County/AutoComplete", request)
                .pipe(map((response: CountyAutocompleteResponse[]) => {
                    const mapping = new SearchResponse()
                    mapping.TotalCount = response.length;
                    mapping.Items = response;

                    return mapping;
                }));
        };
    }

    protected ConfigurePlaceSearchColumn(col: SearchColumn): void {
        col.filterOperator = SearchFilterOperatorEnum.Equals;   //  *NOT* contains - we are selecting exact value from autocomplete!  Contains will do "like" search which is very inefficient
        col.autocompleteResultFilterValue = "Name";
        col.autoComplete = true;
        col.autoCompleteSearchFunction = (filter: SearchRequest) => {
            const searchValue = filter.Filters[0].Values[0].FilterValue;
            const request = new PlaceAutocompleteRequest(null, searchValue, false, false);

            return this.services.http.post(this.services.settingsService.ApiBaseUrl + "/Maps/Place/AutoComplete", request)
                .pipe(map((response: PlaceAutocompleteResponse[]) => {
                    const mapping = new SearchResponse()
                    mapping.TotalCount = response.length;
                    mapping.Items = response;

                    return mapping;
                }));
        };
    }
}
