<div [formGroup]="FormGroup" class="flex-column">
    <fieldset class="primary-border-color flex-column" style="margin-top:10px">
        <legend class="primary-color">Attempted Contact:</legend>
        <div class="flex-row" style="column-gap:20px">
            <mat-form-field>
                <mat-label>Date/Time</mat-label>
                <input matInput type="search" [mtxDatetimepicker]="attemptedContactDateTimePicker" formControlName="AttemptedContactDateTime"
                       (focus)="attemptedContactDateTimePicker.open()" (click)="attemptedContactDateTimePicker.open()" />
                <mtx-datetimepicker-toggle matSuffix [for]="attemptedContactDateTimePicker"></mtx-datetimepicker-toggle>
                <!-- [multiYearSelector]="true" [timeInterval]="15" -->
                <mtx-datetimepicker #attemptedContactDateTimePicker type="datetime" [timeInput]="true" mode="portrait"
                                    [twelvehour]="true"></mtx-datetimepicker>
                <mat-error iqValidationMessage [control]="FormGroup.controls.AttemptedContactDateTime"></mat-error>
            </mat-form-field>

            <mat-form-field style="flex-grow:1">
                <mat-label>Method</mat-label>
                <mat-select formControlName="AttemptedContactMethod">
                    <mat-option *ngFor="let method of AvailableContactMethods" [value]="method.Value">{{method.Name}}</mat-option>
                </mat-select>
                <mat-error iqValidationMessage [control]="FormGroup.controls.AttemptedContactMethod"></mat-error>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>Name of the person/excavator the locator attempted to contact</mat-label>
            <input matInput type="search" formControlName="NameOfPersonAttemptedToContact" />
            <mat-error iqValidationMessage [control]="FormGroup.controls.NameOfPersonAttemptedToContact"></mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Phone number or email of the person/excavator</mat-label>
            <input matInput type="search" formControlName="PhoneOrEmailOfPerson" />
            <mat-error iqValidationMessage [control]="FormGroup.controls.PhoneOrEmailOfPerson"></mat-error>
        </mat-form-field>
    </fieldset>

    <fieldset class="primary-border-color flex-column" style="margin-top:10px">
        <legend class="primary-color">Locator:</legend>
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput type="search" formControlName="LocatorName" />
            <mat-error iqValidationMessage [control]="FormGroup.controls.LocatorName"></mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Phone number or email</mat-label>
            <input matInput type="search" formControlName="PhoneOrEmailOfLocator" />
            <mat-error iqValidationMessage [control]="FormGroup.controls.PhoneOrEmailOfLocator"></mat-error>
        </mat-form-field>
    </fieldset>

    <mat-form-field style="padding-top:10px; width:20em">
        <mat-label>Proposed date/time of completion</mat-label>
        <input matInput type="search" [mtxDatetimepicker]="proposedDateTimeOfCompletionDateTimePicker" formControlName="ProposedDateTimeOfCompletion"
               (focus)="proposedDateTimeOfCompletionDateTimePicker.open()" (click)="proposedDateTimeOfCompletionDateTimePicker.open()" />
        <mtx-datetimepicker-toggle matSuffix [for]="proposedDateTimeOfCompletionDateTimePicker"></mtx-datetimepicker-toggle>
        <!-- [multiYearSelector]="true" [timeInterval]="15" -->
        <mtx-datetimepicker #proposedDateTimeOfCompletionDateTimePicker type="datetime" [timeInput]="true" mode="portrait"
                            [twelvehour]="true"></mtx-datetimepicker>
        <mat-error iqValidationMessage [control]="FormGroup.controls.ProposedDateTimeOfCompletion"></mat-error>
    </mat-form-field>
</div>
