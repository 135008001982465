import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { ListFilterService } from '@iqSharedComponentControls/Lists/Filters/Services/ListFilter.service';
import { ListColumnService } from '@iqSharedComponentControls/Lists/ListColumn.service';
import { Dictionary } from '@iqSharedUtils/Dictionary';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SignInState, ViewStateEnum } from 'iqCognito/models';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { AppUser } from 'Models/Security/AppUser.model';
import { LinkedServerSetting } from 'Models/Security/LinkedServerSetting.model';
import { ToastrService } from 'ngx-toastr';
import { TicketHelpService } from 'Pages/Tickets/Help/Services/TicketHelpService.service';
import { BehaviorSubject, Observable, of, ReplaySubject, zip } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, finalize, map, mergeMap, retry, take } from 'rxjs/operators';
import { EnumService } from 'Services/Enum.service';
import { GoogleGtagService } from "Services/GoogleAnalytics/google-gtag.service";
import { SettingsService } from 'Services/SettingsService';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { Guid } from 'Shared/Utils/Guid';
import { environment } from '../../environments/environment';
import { TicketDashboardService } from '../Pages/Tickets/Dashboard/Services/TicketDashboard.service';
import { CognitoAdminService } from './CognitoAdminService';

/** Used as the state to send to Amplify to keep a state after logging in with a federated login provider. i.e. Google, Facebook, etc */
export class ExactixFederatedLoginState {
    /** A Url to redirect to after we get the user logged in event */
    returnUrl: string;
    /** Flag to determine if it should redirect back to the page before calling the API to get the user info
     *
     * if 'true' it will redirect right away.  Use this when the page redirecting to handles getting the user info, like the link-login page.
     * if 'false' it will call the API to get the user info and then redirect to the url. Use this when the page redirecting to does not load the user info, like the login page.
     * */
    redirectBeforeFetchingUser: boolean = false;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private _user: CognitoUser = null;

    private appUser = new BehaviorSubject<AppUser>(null);

    private _cognitoAttrs: CognitoUserAttribute[];

    //Set when the call to our API completes getting the user info.  This does not mean that the CurrentUser is set properly yet because cognito has to make
    //  a call to get the attributes stored there.
    //It is set this way (and not after the attributes are fetched) because at times we need to know when this call is finished so we can then make other
    //  calls to the server and don't get a race condition on setting the user session currentOccCode that can cause issues.
    FetchedUserInfo = new BehaviorSubject<boolean>(false);

    //Different than IsUserAuthorized because this is listened on.  We can't really listen on IsUserAuthorized because it doesn't change when logged out or in
    UserIsSignedIn = //new BehaviorSubject<boolean>(false);
     this.cogService.signInEventChange$.pipe(map(val => val.SignedIn));


    private _startupTokenFectchedHaveLogin = new ReplaySubject<boolean>(1);

    private _isFirstSigninEvent = true;
    constructor(private settingsService: SettingsService, private http: HttpClient, private router: Router, private route: ActivatedRoute,
        private _Injector: Injector,
        private cogService: IqAwsCognitoService,
        private cognitoAdminService: CognitoAdminService, private matDialog: MatDialog, private toastrService: ToastrService,
        @Optional() private gtagSrvice: GoogleGtagService
    ) {

        //For some reason this fires twice when logging in.  So we do the distinctUntil to stop it because it will mess up things
        cogService.signInEventChange$.pipe(distinctUntilChanged((prev: SignInState, cur: SignInState) => prev.SignedIn === cur.SignedIn))
            .subscribe(val => {

                //This page will handle this status itself
                if (this.router.url.startsWith("/link-login")) {
                    this._isFirstSigninEvent = false;
                    return;
                }

                if (val.SignedIn) {
                    //If there is a return state then we logged in with a federated provider. i.e. Google, Facebook, etc.
                    const federatedLoginState: ExactixFederatedLoginState = val.FederatedLoginReturnState ? val.FederatedLoginReturnState.CustomState : null;

                    //If there is a return url set then handle it.
                    if (federatedLoginState && federatedLoginState.returnUrl && federatedLoginState.returnUrl.trim() !== "") {

                        //If the page handles the the sign in event, then this tells us to just redirect to that page.
                        //  If not then set the redirectUrl so that they will get redirected to it after we get the login info.  i.e. they try to deep link to a page
                        if (federatedLoginState.redirectBeforeFetchingUser) {
                            this.router.navigateByUrl(federatedLoginState.returnUrl);
                            return;
                        }
                        else
                            this._redirectUrl = federatedLoginState.returnUrl
                    }

                    this.SetUserLoggedIn(val.User, false, val.UsedSso);
                }
                else {
                    //Check for an sso value for the app we are embeded into as an iframe.
                    if (environment.isEmbedded && this._isFirstSigninEvent) {
                        cogService.TrySiginFromSingleSignOn(environment.embeddedAuthConfig)
                            .pipe(take(1)).subscribe(val => {
                                this._startupTokenFectchedHaveLogin.next(val);
                            });
                    }
                    else {
                        this.CurrentUser = new AppUser();
                        this._cognitoAttrs = null;
                        this._startupTokenFectchedHaveLogin.next(false);
                    }
                }

                this._isFirstSigninEvent = false;
            });
    }

    public AcceptTermsAndConditions(appUser: AppUser): Observable<AppUser> {
        //Maybe move this to the setAppUser Method
        if (appUser && appUser.TermsAndConditions) {
            const modalData: DialogModel = {
                Message: appUser.TermsAndConditions,
                ActionText: 'Accept',
                CancelText: 'Decline and Log off',
                Title: 'Terms & Conditions',
                ConfirmationText: null,
                ShowInPre: false
            };
            return this.matDialog.open(ConfirmationDialogComponent, {
                data: modalData,
                disableClose: true,
                autoFocus: false        //  Prevent auto focus or it will focus the first control in the content which could cause it to scroll to the bottom (if there is link at the bottom)
            }).afterClosed().pipe(mergeMap(val => {
                if (val === false) {
                    return of(null);
                }

                return this.http.post(this.settingsService.ApiBaseUrl + "/Administration/Person/AcceptTermsAndConditions", '"' + appUser.ID + '"')
                    .pipe(map(success => appUser), retry(2),//retry twice before we fail and log them out
                        catchError(err => {
                            this.toastrService.error("Something went wrong, please try again.");
                            return of(null);
                        }));
            }));
        }

        return of(appUser);
    }


    public SetUserLoggedIn(user: CognitoUser, forceRedirect: boolean = false, loginFromSso: boolean = false) {
        this._startupTokenFectchedHaveLogin.next(true);
        this._user = user;
        //Get these before we call the server to get the userInfo because we need to pass them because the token may not have the right
        //  values.  This can happen if the user changes the email or preferred_username and the token hasn't been updated (i.e. updated
        //  then then refreshed the page)
        this._user.getUserAttributes((err, attrs) => {
            if (!err)
                this._cognitoAttrs = attrs;

            this.GetUserInfo(forceRedirect, loginFromSso);
        });
    }
    private _redirectUrl: string = null;

    get RedirectUrl() {
        if (this._redirectUrl)
            return this._redirectUrl;

        return this.route.snapshot.queryParamMap.get("returnUrl");
    }

    public CurrentUserObserver(includeEmptyUser: boolean = false): Observable<AppUser> {
        return this.appUser.pipe(filter(val => {

            //Don't emit if the user is empty
            if (val && (includeEmptyUser || val.Username !== undefined))
                return true;

            return false;
        }));
    }

    private _appUser: AppUser = null;
    get CurrentUser(): AppUser {
        return this._appUser;
    }
    set CurrentUser(val: AppUser) {

        this._appUser = val;
        this.appUser.next(val);

        this.settingsService.CurrentOneCallCenterCode = val ? val.CurrentOneCallCenterCode : null;
    }

    private setAppUser(appUser: AppUser) {

        if (appUser) {

            const entityPermmissions = new Dictionary<PermissionsEnum[]>();
            for (const key in appUser.EntityPermissions)
                entityPermmissions.Add(key, appUser.EntityPermissions[key]);

            appUser.EntityPermissions = entityPermmissions;

            appUser.IsExternalLogin = false;

            if (this._cognitoAttrs) {//Should have them, but don't break if for some reason we don't
                this._cognitoAttrs.forEach(att => {
                    switch (att.getName()) {
                        case "given_name":
                            appUser.FirstName = appUser.FirstName || att.getValue();
                            break;

                        case "family_name":
                            appUser.LastName = appUser.LastName || att.getValue();
                            break;

                        case "email":
                            appUser.EmailAddress = appUser.EmailAddress || att.getValue();
                            break;

                        case "email_verified":
                            if (!att.getValue().toBoolean() && this._user?.getUsername())
                                this.cognitoAdminService.CurrentLoginChangeEmail(this._user.getUsername(), true).subscribe();
                            break;

                        case "sub":
                            if (this.gtagSrvice) {
                                this.gtagSrvice.set({
                                    user_id: att.getValue()
                                });
                            }
                            break;

                        case "identities":
                            appUser.IsExternalLogin = true;
                            break;
                    }
                });
            }

            //Have to call this in here so we don't set the CurrentUser until the properties are all filled because the getUserAttribute method has a callback

            //username can only come from the profile - which may not be set if we got redirected from handleLoginNotConnectedToPerson!
            appUser.Username = this._user?.getUsername();

            appUser.FullName = appUser.FirstName + ' ' + appUser.LastName;

            this.CurrentUser = appUser;
        }
        else
            this.CurrentUser = null;
    }

    //Used in the AuthenticationGuardService to determine if the person is valid as a promise
    get IsUserAuthorized(): Observable<boolean> {
        return this._startupTokenFectchedHaveLogin.pipe(concatMap(appStarted => {

            if (appStarted === false)
                return of(false);

            return this.cogService.IsSessionValid().pipe(
                concatMap(isSessionValid => {
                    const isValid = isSessionValid;
                    if (!isValid) {
                        this.cogService.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
                        this.cogService.SignOut().subscribe();
                        return of(isValid);
                    }

                    //This will make sure that we don't return they are authorized unless we have called getUserInfo (won't return if there isn't a user set), but should return after we tried to call and get the info, it just won't have an ID set because if it fails it gets set with an instance of a new AppUser
                    return this.CurrentUserObserver(true).pipe(map(user => {
                        return isValid;
                    }));
                }), catchError(() => {
                    this.cogService.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
                    this.cogService.SignOut().subscribe();
                    return of(false);
                }));
        }));
    }

    public getAuthorizationHeaderValueObservable(): Observable<string> {
        return this.cogService.GetTokens().pipe(take(1), map(val => {
            return "Bearer " +  val.getIdToken().getJwtToken();
        }), catchError((err) => {
            //Don't do this here or we can't call the API to reset the password by an email because it needs to call the API anonymously and not change the view state to be the login view
            //this.cogService.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
            return of(null)
        }));
    }


    public SwitchServer(server: LinkedServerSetting): void {
        //Go to the root route that the person in currently on.
        const urlParts = this.router.url.split("/");
        const redirectBackToRoute = urlParts.length >= 2 ? urlParts[1] : null;

        if (server.URL && server.URL !== "")
            window.location.href = server.URL + "/" + redirectBackToRoute;
        else {
            this.router.navigateByUrl("/switch-one-call").then((success) => {

                //If for some reason it doesn't navigate (a deactivate route guard cancelled it) then don't continue to switch the center
                if (success === false)
                    return;

                this.ClearCachesOnLogout();

                //Make sure we have the latest incase they changed anything.
                this.cogService.GetCurrentAuthenticatedUser().pipe(take(1)).subscribe(val => {

                    //If we don't get a valid user then we probably need to log them out..
                    val.getUserAttributes((err, atts) => {
                        //Still send the request if we don't get the attrs.  We may not get them if the user we have is expired...May need to find a way to update the user we have.
                        this.http.post<AppUser>(this.settingsService.ApiBaseUrl + "/Administration/Person/SwitchOneCall", { OccCode: server.Code, CognitoAttributes: atts })
                            .subscribe(userInfo => {
                                //this.setAppUserAndRedirect(userInfo);
                                this.setAppUser(userInfo);
                                if (redirectBackToRoute && redirectBackToRoute !== "")
                                    this.router.navigateByUrl(redirectBackToRoute);
                                else
                                    this.router.navigateByUrl("/home");
                            });
                    });
                });
            });
        }
    }

    private GetUserInfo(forceRedirect: boolean = false, loginFromSso: boolean = false): void {
        //Send the CogAttrs because the token may be outdated if the user updated the email or preferred_username and the token hasn't gotten refreshed.
        this.http.post<AppUser>(this.settingsService.ApiBaseUrl + "/Administration/Person/GetUserInfo", this._cognitoAttrs)
            .pipe(finalize(() => this.FetchedUserInfo.next(true)),
                mergeMap((user: AppUser) => {
                    return this.AcceptTermsAndConditions(user);
            }))
            .subscribe(user => {

                if (!user) {
                    this.logout();
                    return;
                }

                //If it gets here and has an empty ID it is a super user. Need to give them an option to create a person in the app.
                if (user.ID === Guid.empty) {
                    //If we didn't get a person associated to the login redirect them to try and do something about it.
                    user.ID = null;
                    this.handleLoginNotConnectedToPerson(user);
                    return;
                }

                if (user.LoginDisabled) {
                    this.handleLoginNotConnectedToPerson(user);
                    return;
                }

                //Kind of a lame way to do this, but we need some way to take the user to the configured landing page after login, but also keep
                //  them on the same page if they are logged in and are just refreshing the browser
                //url != "/login" is for if they ever refresh or deep link to the login page, but are already logged in.
                //!url.startsWith("/googleResponse?code") is for when they get redirected back from signing in with an external provider like Google
                if (forceRedirect === false && this.router.url !== "/login" && !this.router.url.startsWith("/googleResponse") && !this.RedirectUrl) {
                    this.setAppUser(user);
                    return;
                }

                this.setAppUserAndRedirect(user);
            },
                error => {
                    //If we are embedded and didn't find a person then logout and reset the config the login control uses to be Exactix
                    if (environment.isEmbedded || loginFromSso) {
                        this.logoutObservable(true).subscribe(val => {
                            this.cogService.ChangeAuthConfig();
                        });

                        return;
                    }

                    //  503 is System Unavailable and is handled globally in the ApiInterceptor (in case that's ever triggered by other api calls)
                    if (error.status !== 503)
                        this.handleLoginNotConnectedToPerson(null);
                });
    }

    private handleLoginNotConnectedToPerson(appUser: AppUser): void {

        //Google login redirect
        //  link-login shouldn't need to be added here because it handles all the login events on that page.  So it can probably be removed from the if statement below this one,
        //  but I'm not sure and I don't want to break anything right now, so I'm leaving it alone until I can test it to make sure.
        if (this._redirectUrl === "/newUser") {
            this.setAppUserAndRedirect(appUser || new AppUser());
            return;
        }

        //If they are trying to register or link to a person then leave it be.
        if (this.router.url === "/newUser" || this.router.url.startsWith("/link-login")) {
            this._redirectUrl = null;//Clear out anything that was trying to be redirected to because they can't redirect after this
            this.setAppUser(appUser || new AppUser());
            return;
        }

        //Else they aren't connected to a person and not trying to connect so need to show the not authorized page
        this._redirectUrl = 'auth-no-person-for-login';

        //set what info we can from the login user
        if (!appUser)
            appUser = new AppUser();

        this.setAppUserAndRedirect(appUser);
    }

    public setAppUserAndRedirect(appUser: AppUser) {
        this.setAppUser(appUser);

        if (this.RedirectUrl) {
            this.router.navigateByUrl(this.RedirectUrl);
            this._redirectUrl = null;
        }
        else
            //this.router.navigateByUrl('/tickets/dashboard');
            this.router.navigateByUrl(appUser.ConfiguredLandingPage);
    }

    //Different actions if we are redirecting after the user has registered vs if they just logged in.  i.e. we want to ignore any redirects
    //  and also allow the redirect from the newUser page
    public redirectAfterUserRegistration(appUser: AppUser, cogLogin: CognitoUser, attributes: CognitoUserAttribute[] = []) {

        //Set this so that the authguard knows that it can go
        this._startupTokenFectchedHaveLogin.next(true);

        this._cognitoAttrs = attributes;
        this._user = cogLogin;

        this.AcceptTermsAndConditions(appUser).pipe(take(1)).subscribe(val => {
            if (!val) {
                this.logout();
                return;
            }

            this.setAppUser(val);
            //Set this so that place know that the userinfo is fetched and the app can move on.
            this.FetchedUserInfo.next(true);

            this.router.navigateByUrl(val.ConfiguredLandingPage);
        });
    }

    //Called on an error while refreshing the token (most likely, because it's expired) or on receiving an unauthorized error.
    //  Should probably capture the request and have them login again and then try the call again.  But we could also get this if they are logged in and just don't have permission
    //  to do what they were trying to do.
    public HandleUnauthorizedError(): void {
        //If they have a valid token still and they get here we may want to just try the api call again.  For some reason the api call failed to authenticate...See notes in the comment on
        //  the method about things to watch out for

        //If they don't have a valid token then log them out.
        this.IsUserAuthorized.pipe(take(1)).subscribe(val => {
            if (!val)
                this.logout();
        });
    }

    //  Don't call this directly unless you are sure there are no CanDeactivateGuards on
    //  the current page!  Otherwise, the user will be logged out before they have a chance to
    //  prevent the log out.
    public logoutObservable(redirectToLogin: boolean = true) {
        return new Observable<boolean>(observer => {
            this.ClearCachesOnLogout();

            //Clear out the session on the server
            //  Ignore errors on the api call!  Otherwise, a server/network error will cause the app to be stuck with
            //  no way to get the cookie cleared or to the login page!
            zip(
                this.http.post(this.settingsService.ApiBaseUrl + "/Administration/Person/ClearSession", null),

                //  Must pass true here or a Google login is not logged out correctly (and results in immediately logging back
                //  in when the Login page is displayed).
                //I removed this and don't see that happening...If it happens add it back in and please let me know how to get it to happen. I've tried:
                //  1. Multiple google logins to pick from on the google screen
                //  2. Ony 1 google login to pick from on the google screen
                //  3. No goolge login and adding one on the google screen
                this.cogService.SignOut().pipe(take(1))
            ).pipe(take(1))
                .subscribe(null, null, () => {
                    //On success or failure we want to do this.
                    this.FetchedUserInfo.next(false);
                    this._user = null;
                    this.CurrentUser = null;

                    if (redirectToLogin)
                        this.router.navigate([""]);

                    observer.next(true);
                    observer.complete();
                });
        });
    }

    //  Better way to log out is to just navigate to /logout.  That will allow any CanDeactivateGuard
    //  checks to be fired before the logout happens.
    //  May want to change this method to do that but (other than maybe the Api Interceptor's handling of
    //  an unauthorized response), didn't think we any other cases would have an issue with a guard.
    public logout(redirectToLogin = true): void {
        this.logoutObservable(redirectToLogin).pipe(take(1)).subscribe();
    }

    private ClearCachesOnLogout(): void {
        //  Do *NOT* inject these via the constructor!  It creates circular dependencies.  This is not currently causing problems but it should be...
        //  ** There are 2 tools that can be used to find circular dependencies.  But, they both tend to excessively detect type (model) references
        //  which are still valid.  So it can be difficult to find the real problems.  But they may help if the app is just completely blowing up.
        //      https://github.com/acrazing/dpdm
        //      https://github.com/pahen/madge

        this._Injector.get(EnumService).ClearCache();
        this._Injector.get(ListColumnService).ClearCache();
        this._Injector.get(ListFilterService).ClearCache();
        this._Injector.get(TicketHelpService).ClearCache();
        this._Injector.get(TicketDashboardService).ClearState();

        this.settingsService.TicketEntryDisclaimerAccepted = false;
    }
}
