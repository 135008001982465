import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EntityEnum } from 'Enums/EntityType.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchFilter, SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { TicketSearchQueryConfiguration } from 'Pages/Tickets/Search/Models/TicketSearchQueryConfiguration';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { SettingsService } from 'Services/SettingsService';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';
import { CRUDBaseService } from 'Shared/BaseServices/CRUDBase.service';
import { ObjectUtils } from 'Shared/Utils/ObjectUtils';
import { SelectOption } from '../../../../Models/Configuration/SelectOption.model';
import { IEntity } from '../../../../Models/Interfaces/IEntity.interface';
import { AuthenticationService } from '../../../../Services/AuthenticationService';
import { TicketActionsService } from '../../Services/TicketActions.service';

@Directive()
export class PhoneTicketListBase extends ComponentWithDestroySubscription implements OnInit {
    protected EntityEnum: EntityEnum = EntityEnum.Ticket;

    private _Config: TicketSearchQueryConfiguration;
    @Input() set Config(val: TicketSearchQueryConfiguration) {
        this._Config = ObjectUtils.Copy(val);

        if (val && this._ComponentLoaded)
            this.LoadView();
    }
    get Config() {
        return this._Config;
    }

    @Input() TicketNumber: string;
    @Output() TicketNumberChanged: EventEmitter<string> = new EventEmitter();

    public AvailableSearchColumns: SearchColumn[] = [];
    public AvailableSearchFilters: SearchColumn[] = [];

    public dateFormat: string;

    public minCharsDefaultSearch = this.settingsService.TicketNumberSearchRequiredChars;
    public Items: any[] = [];

    public totalResults = 0;

    public loading = false;

    public ticketNumberControl: UntypedFormControl;

    private _CurrentSearchRequest: SearchRequest = null;
    private _ComponentLoaded = false;//Used to know when to call the loadview if the config changes, or to wait because it will get done in the OnInit

    public CanMultiSelect: boolean = false;

    public selectedItems: IEntity[] = new Array<IEntity>();

    public ActionList: SelectOption[] = [];
    public AllowMultipleActionsOnTicketList: boolean = false; 


    constructor(private _CRUDService: CRUDBaseService<any>, protected settingsService: SettingsService,
        protected ticketActionsService: TicketActionsService, authenticationsService: AuthenticationService) {
        super();
        this.dateFormat = settingsService.DateTimeFormat;

        authenticationsService.CurrentUserObserver().pipe(take(1)).subscribe((user) => {
            this.AllowMultipleActionsOnTicketList = user.AllowMultipleActionsOnTicketList;
        });
    }

    public ngOnInit(): void {
        this.ticketNumberControl = new UntypedFormControl(this.TicketNumber);
        this.ticketNumberControl.valueChanges.pipe(debounceTime(500), takeUntil(this.Destroyed)).subscribe(val => {
            if (val) {
                if (val.length >= this.minCharsDefaultSearch) {
                    this.TicketNumber = val;
                    this.TicketNumberChanged.emit(val);
                    this.LoadView();
                }
            }
            else {
                //Clear out the stored value if the user cleared it out.
                if (this.TicketNumber && this.TicketNumber.length > 0) {
                    this.TicketNumber = null;
                    this.TicketNumberChanged.emit(null);
                }

                this.LoadView();
            }
        });

        this._CRUDService.GetAvailableSearchColumnsAndFilters().pipe(take(1))
            .subscribe((val: { columns: SearchColumn[], filters: SearchColumn[] }) => {
                if (val) {
                    this.AvailableSearchColumns = val.columns;
                    this.AvailableSearchFilters = val.filters;
                }
            });

        this.LoadView();
        this._ComponentLoaded = true;
    }

    public ToggleMultiSelect(enabled: boolean): void {
        if (!enabled)
            this._ClearSelectedItems();

        if (this.AllowMultipleActionsOnTicketList) {
            setTimeout(() => {
                if (this.selectedItems.length > 0) 
                    this._GetAllowedActions();

                this.CanMultiSelect = enabled;
            });
        }
    }

    public ToggleSelected(item: IEntity) {
        if (item.Selected)
            this.selectedItems.push(item);
        else
            this.selectedItems.splice(this.selectedItems.findIndex(val => val.ID === item.ID), 1);

        if (this.selectedItems.length > 0)
            this._GetAllowedActions();
        else
            this.ActionList = [];
    }

    private _GetAllowedActions(): void {
        this.ActionList = [];

        const filters = this.GetSearchRequest().Filters;
        const deleteRowsForCompletedResponses = filters && filters.some(f => f.PropertyName === "HaveCompletedResponse");
        this.ticketActionsService.GetAllowedTicketActionsMenuItems(this.selectedItems, null, (workCompleted: boolean) => this.MarkTicketsComplete(workCompleted), this.AvailableSearchColumns,
            (entity: IEntity) => this.DeleteItem(entity), deleteRowsForCompletedResponses, () => this._ClearSelectedItems())
            .pipe(take(1)).subscribe(actionList => this.ActionList = actionList);
    }

    private _ClearSelectedItems() {
        this.selectedItems.map(m => m.Selected = false);
        this.selectedItems = new Array<IEntity>();
        this.ActionList = [];
    }

    private DeleteItem(entity: IEntity) {
        entity.Selected = false;
        this.ToggleSelected(entity);
        this.Items = this.Items.filter(m => m !== entity);
    }

    public MarkTicketsComplete(workCompleted: boolean) {
        //  The TicketActions.GetAllowedTicketActionsMenuItems() function adds the api call to actually call
        //  the MarkWorkCompleted method.  This method is called after that completes.
        this.ToggleMultiSelect(false);
    }

    public UpdateFiltersAndSort(filters: SearchFilter[], orderBy: SearchOrderBy[]): void {
        this._Config.DefaultFilters = filters;
        this._Config.OrderBy = orderBy;

        this.LoadView();
    }

    private GetSearchRequest(): SearchRequest {
        const request = new SearchRequest();
        request.Columns = this._Config.Columns;
        if (this._Config.FetchAdditionalColumns)
            request.Columns = [].concat(request.Columns, this._Config.FetchAdditionalColumns);
        request.EntityType = this.EntityEnum;

        let filters: SearchFilter[];

        if (this.TicketNumber && this.TicketNumber.length >= this.minCharsDefaultSearch) {
            filters = [new SearchFilter("TicketNumber", SearchFilterOperatorEnum.StartsWith, [new SearchFilterValue(this.TicketNumber, this.TicketNumber)])];
            if (this.Config.FiltersRequiredForTicketNumberSearch)
                filters = filters.concat(this.Config.FiltersRequiredForTicketNumberSearch);
        }
        else {
            filters = [];
            if (this._Config.ViewFilters)
                filters = this._Config.ViewFilters;
            if (this._Config.DefaultFilters)
                filters = filters.concat(this.Config.DefaultFilters);
        }

        request.Filters = filters;
        request.OrderBy = this._Config.OrderBy;
        request.PageSize = 50;
        request.PageNum = 1;

        return request;
    }

    private LoadView() {
        this._CurrentSearchRequest = this.GetSearchRequest();
        this.LoadItems(true);
    }

    private LoadItems(replaceItems = false) {
        this._CRUDService.GetList(this._CurrentSearchRequest).pipe(take(1)).subscribe(val => {
            if (val?.Items) {
                if (replaceItems)
                    this.Items = val.Items;
                else {
                    //Don't replace the Items with a new list or the virtual scroll will re-draw itself.  Just add them to it.
                    val.Items.forEach(f => this.Items.push(f));
                }
            }
            else
                this.Items = [];

            this.totalResults = val?.TotalCount ?? 0;

            this.loading = false;
        });
    }

    public FetchNextPage() {
        if (!this.Items || this.Items.length >= this.totalResults || this.loading)
            return;

        this.loading = true;
        this._CurrentSearchRequest.PageNum++;
        this.LoadItems();
    }
}
