
import {takeUntil} from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { Directive, ElementRef, OnDestroy, Input } from '@angular/core';
import { Subject } from "rxjs";
import { CustomValidationError } from './CustomValidationError';
import moment from 'moment';


//If you notice this does not do anything to determine if the item is shown or not.  It is not meant to.
//  We may want to show the error based on if the person has touched the control or always show it or something else.
//  If you want to determine when to show the message create a different directive (or if you can just put this on the mat-error control and it will do some of that handling for you).
@Directive({
    selector: '[iqValidationMessage]'
})
export class ValidationMessageDirective implements OnDestroy {
    private destroyed$: Subject<void> = new Subject();

    private _control: AbstractControl;
    @Input()
    set control(ctl: AbstractControl) {
        this._control = ctl;
        this.SetErrorMessage();

        this._control.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            this.SetErrorMessage();
        });
    }

    constructor(private el: ElementRef) {
    }

    SetErrorMessage() {
        
        //The form control can only have one error at a time.
        this.el.nativeElement.innerHTML = "";
        if (!this._control.invalid || !this._control.errors)
            return;

        if (this._control.errors.required)
            this.el.nativeElement.innerHTML = "Required";

        if (this._control.errors.pattern)
            this.el.nativeElement.innerHTML = "Format error";

        if (this._control.errors.noID || this._control.errors.missingProp)
            this.el.nativeElement.innerHTML = "No item selected";

        if (this._control.errors.maxlength)
            this.el.nativeElement.innerHTML = "Maximum length (" + this._control.errors.maxlength.actualLength + "/" + this._control.errors.maxlength.requiredLength + ")";

        if (this._control.errors.minlength)
            this.el.nativeElement.innerHTML = "Minimum length (" + this._control.errors.minlength.actualLength + "/" + this._control.errors.minlength.requiredLength + ")";

        if (this._control.errors.address)
            this.el.nativeElement.innerHTML = "Address not valid";

        if (this._control.errors.matDatepickerParse)
            this.el.nativeElement.innerHTML = "Invalid Date Format";

        if (this._control.errors.matDatepickerMin) {
            //  Could be a string - if so, convert it so we can format
            let minDate = this._control.errors.matDatepickerMin.min;
            if (!minDate.format)
                minDate = moment(minDate);
            this.el.nativeElement.innerHTML = "Date must be after " + minDate.format("l");
        }

        if (this._control.errors.iqEarliestTime)
            this.el.nativeElement.innerHTML = "Time must be after " + this._control.errors.iqEarliestTime;

        if (this._control.errors.iqLatestTime)
            this.el.nativeElement.innerHTML = "Time must be before " + this._control.errors.iqLatestTime;

        if (this._control.errors.iqAlreadyExists)
            this.el.nativeElement.innerHTML = "Item with value already exists";

        if (this._control.errors.customMessage)
            this.el.nativeElement.innerHTML = this._control.errors.customMessage;

        if (this._control.errors.UnallowedCharacters)
            this.el.nativeElement.innerHTML = this._control.errors.customMessage;

        if (this._control.errors.dateRangeSize)
            this.el.nativeElement.innerHTML = this._control.errors.dateRangeSize;

        if (this._control.errors.dateRangeOrder)
            this.el.nativeElement.innerHTML = this._control.errors.dateRangeOrder;
        

        //  These are errors created using CustomValidationError.Create() so we don't have to add code in THIS class for
        //  every single custom validation error message...
        if (this._control.errors.iqCustomValidationError) {
            const err = this._control.errors.iqCustomValidationError as CustomValidationError;
            this.el.nativeElement.innerHTML = err.Error;
        }

        if (this._control.errors.min)
            this.el.nativeElement.innerHTML = "Min is " + this._control.errors.min.min;
        if (this._control.errors.max)
            this.el.nativeElement.innerHTML = "Max is " + this._control.errors.max.max;

        if (this.el.nativeElement.innerHTML === "") {
            this.el.nativeElement.innerHTML = "Validation error";
            console.log(this._control.errors);
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
