import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListColumnHeaderChooserComponent } from '@iqSharedComponentControls/Lists/HeaderChooser/ListColumnHeaderChooser.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BusyIndicatorModule } from 'Shared/Modules/BusyIndicator.module';
import { DateTimePickerModule } from 'Shared/Modules/DateTimePicker.module';
import { MaterialModule } from 'Shared/Modules/Material.module';
import { UtilsModule } from 'Shared/Modules/Utils.module';
import { SharedPhoneDevice } from 'Shared/PhoneDevice/SharedPhoneDevice.module';
import { IQPipesModule } from '../../../Pipes/Pipes.module';
import { IconButtonModule } from '../IconButton/IconButton.module';
import { ListItemComponent } from './/Items/ListItem/ListItem.component';
import { ListSelectItemComponent } from './/Items/SelectItem/ListSelectItem.component';
import { ListMenuComponent } from './/Menu/ListMenu.component';
import { ListActionSectionComponent } from './/Sections/ListActionsSection.component';
import { ListFilterSectionComponent } from './/Sections/ListFilterSection.component';
import { SelectListComponent } from './/Select/SelectList.component';
import { ViewListComponent } from './/View/ViewList.component';
import { ListAutocompleteFilterComponent } from './Filters/AutocompleteFilter/AutocompleteFilter.component';
import { ListFilterContainerComponent } from "./Filters/Container/ListFilterContainer.component";
import { ListDateFilterComponent } from './Filters/DateFilter/ListDateFilter.component';
import { ListFilterDialogComponent } from './Filters/Dialog/ListFilterDialog.component';
import { ListFreeTextFilterComponent } from './Filters/FreeTextFilter/ListFreeTextFilter.component';
import { ListFilterBuilderComponent } from './Filters/ListFilterBuilder.component';
import { NumberFilterComponent } from './Filters/NumberFilter/NumberFilter.component';
import { AgentFilterComponent } from './Filters/PersonFilter/AgentFilter.component';
import { PhoneFilterListComponent } from "./Filters/PhoneFilterList/PhoneFilterList.component";
import { PhoneListFilterEditorComponent } from "./Filters/PhoneListFilterEditor.component";
import { PhoneOrderByListComponent } from "./Filters/PhoneOrderByList/PhoneOrderByList.component";
import { ListSelectItemFilterComponent } from './Filters/SelectItemFilter/ListSelectItemFilter.component';
import { ListColumnHeaderComponent } from './Header/ListColumnHeader.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPaginationModule,
        BusyIndicatorModule,
        MaterialModule,
        UtilsModule,
        FontAwesomeModule,
        IQPipesModule,
        IconButtonModule,
        SharedPhoneDevice,
        MatTabsModule,
        DateTimePickerModule
    ],
    declarations: [
        ViewListComponent,
        ListItemComponent,
        SelectListComponent,
        ListSelectItemComponent,
        ListMenuComponent,
        ListFilterSectionComponent,
        ListActionSectionComponent,
        ListColumnHeaderComponent,
        ListColumnHeaderChooserComponent,
        ListFilterBuilderComponent,
        ListDateFilterComponent, ListFreeTextFilterComponent, ListSelectItemFilterComponent, AgentFilterComponent, NumberFilterComponent, ListAutocompleteFilterComponent,
        ListFilterDialogComponent, ListFilterContainerComponent,
        PhoneListFilterEditorComponent, PhoneFilterListComponent, PhoneOrderByListComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        NgxPaginationModule,
        BusyIndicatorModule,
        UtilsModule,
        FontAwesomeModule,
        IQPipesModule,
        SharedPhoneDevice,
        ViewListComponent,
        ListItemComponent,
        SelectListComponent,
        ListSelectItemComponent,
        ListMenuComponent,
        ListFilterSectionComponent,
        ListActionSectionComponent,
        ListColumnHeaderComponent,
        ListColumnHeaderChooserComponent,
        ListFilterBuilderComponent,
        ListDateFilterComponent, ListFreeTextFilterComponent, ListSelectItemFilterComponent, AgentFilterComponent, NumberFilterComponent, ListAutocompleteFilterComponent,
        ListFilterDialogComponent, ListFilterContainerComponent,
        PhoneListFilterEditorComponent, PhoneFilterListComponent, PhoneOrderByListComponent
    ]
})
export class IQListModule { }
