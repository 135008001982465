import { Injectable } from '@angular/core';
import { EntityEnum } from 'Enums/EntityType.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { TicketStatusEnum } from 'Enums/TicketStatus.enum';
import { SearchFilter, SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';
import { TicketDashboardInfoResponse } from 'Models/Tickets/TicketDashboardInfoResponse.model';
import { ManualCalloutListComponent } from 'Pages/Communications/ManualCallouts/ManualCalloutList.component';
import { DesktopTicketResponseSearchComponent } from 'Pages/Tickets/Search/Desktop/DesktopTicketResponseSearch.component';
import { DesktopTicketSearchComponent } from 'Pages/Tickets/Search/Desktop/DesktopTicketSearch.component';
import { TicketListItemViewEnum } from 'Pages/Tickets/Search/Models/TicketListItemViewEnum';
import { TicketSearchQueryConfiguration } from 'Pages/Tickets/Search/Models/TicketSearchQueryConfiguration';
import { PhoneTicketResponseSearchComponent } from 'Pages/Tickets/Search/Phone/PhoneTicketResponseSearch/PhoneTicketResponseSearch.component';
import { PhoneTicketSearchComponent } from 'Pages/Tickets/Search/Phone/PhoneTicketSearch/PhoneTicketSearch.component';
import { TicketMapViewerComponent } from 'Pages/Tickets/TicketMapViewer/TicketMapViewer.component';
import { Subject } from 'rxjs';
import { SettingsService } from 'Services/SettingsService';
import { ListFilterService } from 'Shared/Components/Controls/Lists/Filters/Services/ListFilter.service';


//  Using this enum so that we can have unique values for the views so that we can create a unique key for the lists remembering what was last searched and
//  so that the user can modify the filters on the lists
export enum TicketDashboardViewsEnum {
    MyTickets,
    TodaysTickets,
    Callouts,
    IncompleteTickets,
    SuspendTickets,
    LockedTickets,
    Tickets,//different from My Tickets because this can change based on the user wanting to do by Office, Company, Service Area, Member, etc
    ExpiringTickets,
    TicketsNearMe,
    ResponseStatus,
    PendingTickets,
    ServiceAreaTickets,
    ResponseDue,
    SATicketsOnMap,
    CurrentResponses,
    RecentTickets
}

@Injectable({ providedIn: 'root' })     //  root so that values are persisted thru page navigations
export class TicketDashboardService {

    public LastQueryConfigIndex: number;

    //  Stored here so it is persisted when we switch pages.
    public ImpersonatingExcavatorContactID: string;

    //  TODO: Need to consolidate these properties where it makes sense to do so.  This was all merged from 2 different
    //  services to consolidate them in to 1 shared service to remove the duplication.

    //  These properties are (at least currently) only used by the Desktop views
    public LastLocalUserWebUserSearch: TicketDashboardInfoResponse;
    public LastWebUserEntityFilter: string;
    public ShowCompletedTickets: boolean;

    private _ResponseDueWithinDaysFilterValue: string;
    public get ResponseDueWithinDaysFilterValue(): string { return this._ResponseDueWithinDaysFilterValue }
    public set ResponseDueWithinDaysFilterValue(value: string) {
        this._ResponseDueWithinDaysFilterValue = value;
        this._ResponseDueWithinDaysFilter.Values[1].FilterValue = value;
    }

    //  These properties are (at least currently) only used by the Phone views
    public CurrentView: { GroupID: number, FilterID: number };
    public CurrentFilter: SearchFilter[];
    public CurrentSort: SearchOrderBy[];
    public CurrentTicketNumber: string;

    private _ResponseDueWithinDaysFilter: SearchFilter;
    private _PastDueFilter: SearchFilter;

    constructor(private _SettingsService: SettingsService, public _ListFilterService: ListFilterService)
    {
        this.ClearState();      //  to initialize the inital values so that is only done in 1 place (previously was duplicated in the definitions)
    }

    public ClearState(): void {
        this.LastQueryConfigIndex = 0;
        this.ImpersonatingExcavatorContactID = null;

        this.LastLocalUserWebUserSearch = null;
        this.LastWebUserEntityFilter = null;    //  Don't default - user may not have access to it!
        this.ShowCompletedTickets = false;
        this._ResponseDueWithinDaysFilterValue = "2";

        this.CurrentFilter = null;
        this.CurrentSort = null;
        this.CurrentView = null;
        this.CurrentTicketNumber = null;

        this._ResponseDueWithinDaysFilter = new SearchFilter("ResponseDueDate", SearchFilterOperatorEnum.BusinessDaysBeforeAndAfterToday, [
            new SearchFilterValue("20", null),
            new SearchFilterValue(this.ResponseDueWithinDaysFilterValue, null)
        ]);

        this._PastDueFilter = new SearchFilter("ResponseDueDate", SearchFilterOperatorEnum.BusinessDaysBeforeAndAfterToday, [
            new SearchFilterValue("20", null),
            new SearchFilterValue("0", null)
        ]);
    }

    public RemoveSavedWebUserFilters(): void {
        //  Build all tabs and then clear any stored filters for each one
        const tabs = this.BuildExcavatorUserQueries(null, true);
        tabs.forEach(tab => this._ListFilterService.ClearSavedFilter(tab.StoreFilterKeyValue));
    }

    public static BuildLocalUserQueries(dashboardInfoResponse: TicketDashboardInfoResponse, showCallouts: boolean): TicketSearchQueryConfiguration[] {
        const filterKeyBase: number = EntityEnum.Ticket * 100;
        const orderBy: SearchOrderBy[] = [new SearchOrderBy("TakenEndDate", true)];

        const tabs: TicketSearchQueryConfiguration[] = [
            {
                Name: "My Tickets",
                Description: "*Past 60 days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.MyTickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "Status",
                    "TicketType.Name",
                    "Excavator.CompanyName",
                    "Search.PlaceName",
                    "Search.EnteredStreetAddress",
                    "TakenEndDate",
                    "TicketNumber"
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    //Since this is the local user view we want to search by agent
                    new SearchFilter("AgentPersonID", SearchFilterOperatorEnum.CurrentUser, [new SearchFilterValue("0", "Myself")]),
                    new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue("60", "60")])
                ],
                OrderBy: orderBy,
                RequiredFilters: ["TakenEndDate"],
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.LocalUser_Default
            },
            {
                Name: "Todays Tickets",
                Description: null,
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.TodaysTickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "TicketType.Name",
                    "Status",
                    "Excavator.CompanyName",
                    "Agent.Fullname",
                    "Search.PlaceName",
                    "Search.EnteredStreetAddress",
                    "TicketNumber"
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue("0", "0")])
                ],
                OrderBy: orderBy,
                RequiredFilters: ["TakenEndDate"],
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.LocalUser_Default
            }
        ];

        if (showCallouts) {
            tabs.push({
                Name: "Callouts",
                Description: null,
                Count: dashboardInfoResponse?.CalloutCount,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.Callouts),
                FocusedObservable: new Subject<boolean>(),
                Columns: null,
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: null,
                OrderBy: null,
                RequiredFilters: null,
                DesktopComponent: ManualCalloutListComponent,
                PhoneComponent: null
            });
        }

        tabs.push({
            Name: "Incompletes",
            Description: null,
            Count: dashboardInfoResponse?.IncompletesCount,
            StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.IncompleteTickets),
            FocusedObservable: new Subject<boolean>(),
            Columns: [
                "TakenEndDate",
                "Excavator.CompanyName",
                "CreateSource",
                "Excavator.ContactName",
                "Agent.Fullname",
                "Search.EnteredStreetAddress",
                "Search.PlaceName"
            ],
            ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
            DefaultFilters: [
                //No date needed, should be cleaned up regularly
                new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Incomplete, TicketStatusEnum.Incomplete.toString())])
            ],
            OrderBy: orderBy,
            RequiredFilters: ["Status"],
            DesktopComponent: DesktopTicketSearchComponent,
            PhoneComponent: PhoneTicketSearchComponent,
            ListItemView: TicketListItemViewEnum.LocalUser_Incomplete
        });

        tabs.push({
            Name: "Suspended",
            Description: null,
            Count: dashboardInfoResponse?.SuspendsCount,
            StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.SuspendTickets),
            FocusedObservable: new Subject<boolean>(),
            Columns: [
                "TicketType.Name",
                "CreateSource",
                "TakenEndDate",
                "Excavator.CompanyName",
                "Agent.Fullname",
                "TicketNumber",
            ],
            ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
            DefaultFilters: [
                //No date needed, should be cleaned up regularly
                new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Suspended, TicketStatusEnum.Suspended.toString())])
            ],
            OrderBy: [new SearchOrderBy("TakenEndDate", false)],
            RequiredFilters: ["Status"],
            DesktopComponent: DesktopTicketSearchComponent,
            PhoneComponent: PhoneTicketSearchComponent,
            ListItemView: TicketListItemViewEnum.LocalUser_Suspended
        });

        tabs.push({
            Name: "Locked",
            Description: null,
            Count: dashboardInfoResponse?.LockedCount,
            StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.LockedTickets),
            FocusedObservable: new Subject<boolean>(),
            Columns: [
                "LockedDate",
                "LockedByPerson.Fullname",
                "Agent.Fullname",
                "TicketType.Name",
                "CreateSource",
                "TakenEndDate",
                "Excavator.CompanyName",
                "TicketNumber",
            ],
            ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
            DefaultFilters: [
                new SearchFilter("LockedDate", SearchFilterOperatorEnum.IsNotNull, [])
            ],
            OrderBy: orderBy,
            RequiredFilters: ["LockedDate"],
            DesktopComponent: DesktopTicketSearchComponent,
            PhoneComponent: null        //  Not needed on phone
        });

        return tabs;
    }

    public BuildExcavatorUserQueries(dashboardInfoResponse: TicketDashboardInfoResponse, showPendingTickets: boolean): TicketSearchQueryConfiguration[] {
        const filterKeyBase: number = EntityEnum.Ticket * 100;

        //  *** Every one of these needs to specify a filter on Ticket.Status!
        //  In almost all cases, these tabs should limit to only Complete tickets.  The exception is the handling of Incomplete/Suspend
        //  between the "Tickets" and "Pending Tickets" tabs.
        //  Voids should NEVER be shown to the excavator.
        const tabs: TicketSearchQueryConfiguration[] = [
            {
                Name: "Tickets",
                Description: "*Created in past " + (dashboardInfoResponse?.ExcavatorTicketsDays ?? 30) + " days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.Tickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "Search.EnteredStreetAddress",
                    "Search.PlaceName",
                    "Ancillary.WorkType",
                    "Ancillary.JobNumber",
                    "WorkStartDate",
                    "Excavator.CompanyName",
                    "TicketNumber"
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    //  Filter on Status is set below (because it varies depending on the "Pending Tickets" tab)
                    new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue(dashboardInfoResponse?.ExcavatorTicketsDays ?? 30, null)])
                ],
                OrderBy: [new SearchOrderBy("TakenEndDate", true)],
                RequiredFilters: ["TakenEndDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.ExcavatorUser_Default
            },
            {
                Name: "Expiring Tickets",
                Description: "*In the next " + (dashboardInfoResponse?.ExcavatorExpireDays ?? 30) + " days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.ExpiringTickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "ExpiresDate",
                    "Search.EnteredStreetAddress",
                    "Search.PlaceName",
                    "Ancillary.WorkType",
                    "Ancillary.JobNumber",
                    "WorkStartDate",
                    "TicketNumber",
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("ExpiresDate", SearchFilterOperatorEnum.DaysFromToday, [new SearchFilterValue(dashboardInfoResponse?.ExcavatorExpireDays ?? 30, null)])
                ],
                FiltersRequiredForTicketNumberSearch: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                ],
                OrderBy: [new SearchOrderBy("ExpiresDate", true)],
                RequiredFilters: ["ExpiresDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.ExcavatorUser_Expiring
            },
            {
                //  Per Sandy, "Tickets Near Me" is confusing when not used on a phone...
                Name: "My Tickets on Map",
                Description: null,
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.TicketsNearMe),
                FocusedObservable: new Subject<boolean>(),
                Columns: null,
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: null,
                OrderBy: null,
                RequiredFilters: null,
                DesktopComponent: TicketMapViewerComponent,
                PhoneComponent: TicketMapViewerComponent
            }
        ];

        if (this._SettingsService.UsesPositiveResponse) {
            tabs.push({
                Name: "Response Status",
                Description: "*Created in past " + (dashboardInfoResponse?.ExcavatorTicketsDays ?? 30) + " days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.ResponseStatus),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "ResponseDueDate",
                    "Stat_ResponsesReceivedRatio",
                    "Search.EnteredStreetAddress",
                    "Search.PlaceName",
                    "Ancillary.WorkType",
                    "Ancillary.JobNumber",
                    "WorkStartDate",
                    "TicketNumber"
                ],
                FetchAdditionalColumns: [
                    "TicketNumberStats.AllResponsesReceived"    //  Used to color the Stat_ResponsesReceivedRatio value (configured in TickertService.AddUIFunctionsToSearchColumn)
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue(dashboardInfoResponse?.ExcavatorTicketsDays ?? 30, null)])
                ],
                FiltersRequiredForTicketNumberSearch: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                ],
                OrderBy: [new SearchOrderBy("TakenEndDate", true)],
                RequiredFilters: ["TakenEndDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.ExcavatorUser_ResponseStatus
            });
        }

        //  Can't add reference to AuthenticationService to find this because that service has a dependency on this one (to clear when user logs out)...
        if (showPendingTickets) {
            tabs.push({
                Name: "Pending Tickets",
                Description: "*Incomplete tickets",     //  Also includes Suspends but users don't know the difference
                Count: dashboardInfoResponse?.IncompletesCount,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.PendingTickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "Search.EnteredStreetAddress",
                    "Search.PlaceName",
                    "Ancillary.WorkType",
                    "Ancillary.JobNumber",
                    "WorkStartDate",
                    "Excavator.CompanyName"
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Incomplete, null), new SearchFilterValue(TicketStatusEnum.Suspended, null)]),
                    //  No date on this one - there is a very efficient index that will find Incompletes and Suspends.  And the user should really see all of these.
                ],
                OrderBy: [new SearchOrderBy("TakenEndDate", true)],
                RequiredFilters: [],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.ExcavatorUser_Default
            });

            //  Also limit the first tab to filter on only Complete tickets since the incompletes/suspends are shown here.
            tabs[0].DefaultFilters.push(new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]));
        } else {
            //  If not showing the Pending Tickets filter, limit the 1st tab to anything other than Void.
            tabs[0].DefaultFilters.push(new SearchFilter("Status", SearchFilterOperatorEnum.NotEqual, [new SearchFilterValue(TicketStatusEnum.Void, null)]));
        }

        return tabs;
    }

    public BuildServiceAreaUserQueries(dashboardInfoResponse: TicketDashboardInfoResponse): TicketSearchQueryConfiguration[] {
        const tabs: TicketSearchQueryConfiguration[] = [];
        const filterKeyBase: number = EntityEnum.TicketResponse * 100;

        if (this._SettingsService.UsesPositiveResponse && dashboardInfoResponse?.CanViewServiceAreaResponses) {
            //  This view is only used when a user has permission to view responses.  Otherwise, the TicketResponseService
            //  will check for that permission and not do the search!
            const tabColumns = [
                "Response.Code",
                "ResponseDueDate",
                "EnteredStreetAddress",
                "PlaceName",
                "ServiceArea.Name",
                "WorkType",
                "WorkStartDate",
                "ExcavatorCompany.Name",
                "TicketNumber",
            ];
            if (dashboardInfoResponse?.ServiceAreasUseResponseByUtilityType)
                tabColumns.unshift("UtilityType.Name");

            tabs.push({
                Name: "Service Area Tickets",
                Description: "*Created in past " + (dashboardInfoResponse?.ServiceAreaTicketDays ?? 30) + " days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.ServiceAreaTickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: tabColumns,
                FetchAdditionalColumns: [ "Response.Name", "Response.IsNotComplete" ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    //  Using CreateDate because the db query is much faster - and we aren't showing ticket version/taken date anyway.
                    //  So since we are viewing ticket responses, this is technically correct (and using ticket taken date is not).
                    new SearchFilter("CreateDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue(dashboardInfoResponse?.ServiceAreaTicketDays ?? 30, null)]),
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("Current", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)])
                ],
                FiltersRequiredForTicketNumberSearch: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("Current", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)])
                ],
                OrderBy: [new SearchOrderBy("CreateDate", true)],
                RequiredFilters: ["CreateDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketResponseSearchComponent,
                PhoneComponent: PhoneTicketResponseSearchComponent,
                ListItemView: TicketListItemViewEnum.ServiceAreaUser_Default
            });
        } else {
            //  If does not use positive response (DigSafe), must query against the DesktopTicketSearchComponent!
            tabs.push({
                Name: "Service Area Tickets",
                Description: "*Created in past " + (dashboardInfoResponse?.ServiceAreaTicketDays ?? 30) + " days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.Tickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "Search.EnteredStreetAddress",
                    "Search.PlaceName",
                    "ServiceAreaCodes",
                    "Ancillary.WorkType",
                    "WorkStartDate",
                    "Excavator.CompanyName",
                    "TicketNumber"
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue(dashboardInfoResponse?.ServiceAreaTicketDays ?? 30, null)]),
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                ],
                FiltersRequiredForTicketNumberSearch: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                ],
                OrderBy: [new SearchOrderBy("TakenEndDate", true)],
                RequiredFilters: ["TakenEndDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.ServiceAreaUser_NoPosResp
            });
        }

        if (this._SettingsService.UsesPositiveResponse && dashboardInfoResponse?.CanViewServiceAreaResponses) {
            const tabColumns = [
                "Response.Code",
                "ResponseDueDate",
                "TicketNumber",
                "EnteredStreetAddress",
                "PlaceName",
                "ServiceArea.Name",
                "WorkStartDate",
                "ExcavatorCompany.Name",
                "TicketFunction.Name",
            ];
            if (dashboardInfoResponse?.ServiceAreasUseResponseByUtilityType)
                tabColumns.unshift("UtilityType.Name");    //  2nd column if used
            tabColumns.unshift("TicketType.Name");         //  Always 1st column

            tabs.push({
                Name: "Response Due",
                Description: "*Due in 48 hours",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.ResponseDue),
                FocusedObservable: new Subject<boolean>(),
                Columns: tabColumns,
                FetchAdditionalColumns: ["Response.Name", "Response.IsNotComplete"],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    //  The value for this is set dynamically when picked from a radio - in ResponseDueWithinDaysFilterValueChanged()
                    this._ResponseDueWithinDaysFilter,
                    new SearchFilter("HaveCompletedResponse", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(false, null)]),
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("ResponseRequired", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)]),
                    new SearchFilter("Current", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)])
                ],
                FiltersRequiredForTicketNumberSearch: [
                    //  Also want HaveCompletedResponse and ResponseRequired here?  didn't because thought that might be confusing...
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("Current", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)])
                ],
                OrderBy: [new SearchOrderBy("ResponseDueDate", true)],
                RequiredFilters: ["ResponseDueDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketResponseSearchComponent,
                PhoneComponent: PhoneTicketResponseSearchComponent,
                ListItemView: TicketListItemViewEnum.ServiceAreaUser_Response
            });

            tabs.push({
                Name: "Past Due",
                Description: "*No response and past due",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.ResponseDue),
                FocusedObservable: new Subject<boolean>(),
                Columns: tabColumns,
                FetchAdditionalColumns: ["Response.Name", "Response.IsNotComplete"],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    this._PastDueFilter,
                    new SearchFilter("HaveCompletedResponse", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(false, null)]),
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("ResponseRequired", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)]),
                    new SearchFilter("Current", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)])
                ],
                FiltersRequiredForTicketNumberSearch: [
                    //  Also want HaveCompletedResponse and ResponseRequired here?  didn't because thought that might be confusing...
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                    new SearchFilter("Current", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, null)])
                ],
                OrderBy: [new SearchOrderBy("ResponseDueDate", true)],
                RequiredFilters: ["ResponseDueDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketResponseSearchComponent,
                PhoneComponent: PhoneTicketResponseSearchComponent,
                ListItemView: TicketListItemViewEnum.ServiceAreaUser_Response
            });
        }

        tabs.push({
            //  Per Sandy, "Tickets Near Me" is confusing when not used on a phone...
            Name: "Tickets on Map",
            Description: null,
            Count: null,
            StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.SATicketsOnMap),
            FocusedObservable: new Subject<boolean>(),
            Columns: null,
            ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
            DefaultFilters: null,
            OrderBy: null,
            RequiredFilters: null,
            DesktopComponent: TicketMapViewerComponent,
            PhoneComponent: TicketMapViewerComponent
        });

        if (this._SettingsService.UsesPositiveResponse && dashboardInfoResponse?.CanViewServiceAreaResponses) {
            const tabColumns = [
                "Response.Code",
                "TicketNumber",
                "EnteredStreetAddress",
                "PlaceName",
                "ServiceArea.Name",
                "WorkStartDate",
                "ExcavatorCompany.Name",
                "TicketFunction.Name",
            ];
            if (dashboardInfoResponse?.ServiceAreasUseResponseByUtilityType)
                tabColumns.unshift("UtilityType.Name");    //  2nd column if used
            tabColumns.unshift("TicketType.Name");         //  Always 1st column

            tabs.push({
                Name: "All Responses",
                Description: "*Tickets created in past " + (dashboardInfoResponse?.ServiceAreaResponsesDays ?? 30) + " days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.CurrentResponses),
                FocusedObservable: new Subject<boolean>(),
                Columns: tabColumns,
                FetchAdditionalColumns: ["Response.Name", "Response.IsNotComplete"],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    //  Using CreateDate because the db query is much faster - and we aren't showing ticket version/taken date anyway.
                    //  So since we are viewing ticket responses, this is technically correct (and using ticket taken date is not).
                    new SearchFilter("CreateDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue(dashboardInfoResponse?.ServiceAreaResponsesDays ?? 30, null)]),
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)])
                ],
                FiltersRequiredForTicketNumberSearch: [
                    new SearchFilter("Status", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(TicketStatusEnum.Released, null)]),
                ],
                OrderBy: [new SearchOrderBy("HaveResponse", true), new SearchOrderBy("WorkStartDate", true)],
                RequiredFilters: ["CreateDate"],    //  Must override or defaults will also require WorkStartDate and default it to today if filter is opened
                DesktopComponent: DesktopTicketResponseSearchComponent,
                PhoneComponent: PhoneTicketResponseSearchComponent,
                ListItemView: TicketListItemViewEnum.ServiceAreaUser_Response
            });
        }

        return tabs;
    }

    public static BuildRegulatorUserQueries(): TicketSearchQueryConfiguration[] {
        const filterKeyBase: number = EntityEnum.Ticket * 100;

        return [
            {
                Name: "Recent Tickets",
                Description: "*Created in past 30 days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.RecentTickets),
                FocusedObservable: new Subject<boolean>(),
                Columns: [
                    "TakenEndDate",
                    "TicketNumber",
                    "TicketType.Name",
                    "Excavator.CompanyName",
                    "Search.PlaceName",
                    "Search.EnteredStreetAddress"
                ],
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: [
                    new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue("30", "30")])
                ],
                OrderBy: [new SearchOrderBy("TakenEndDate", true)],
                RequiredFilters: ["TakenEndDate"],
                DesktopComponent: DesktopTicketSearchComponent,
                PhoneComponent: PhoneTicketSearchComponent,
                ListItemView: TicketListItemViewEnum.RegulatorUser_Default
            },
            {
                Name: "Tickets Near Me",
                Description: "*Created in past 30 days",
                Count: null,
                StoreFilterKeyValue: (filterKeyBase + TicketDashboardViewsEnum.TicketsNearMe),
                FocusedObservable: new Subject<boolean>(),
                Columns: null,
                ViewFilters: null,  //  Set dynamically based on radio button - this.SelectedFilters
                DefaultFilters: null,
                OrderBy: null,
                RequiredFilters: null,
                DesktopComponent: TicketMapViewerComponent,
                PhoneComponent: TicketMapViewerComponent
            }
        ];
    }
}
