import { Component, ViewChild } from '@angular/core';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { SettingsService } from 'Services/SettingsService';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { AuthenticationService } from '../../../../../Services/AuthenticationService';
import { TicketActionsService } from '../../../Services/TicketActions.service';
import { TicketListItemViewComponent } from '../../ListItemViews/TicketListItemView.component';
import { PhoneTicketListBase } from '../PhoneTicketListBase';

@Component({
    templateUrl: './PhoneTicketSearch.component.html',
    styleUrls: ['./PhoneTicketSearch.component.scss'],
    animations: [slideInFromRight]
})
export class PhoneTicketSearchComponent extends PhoneTicketListBase {
    @ViewChild(TicketListItemViewComponent)
    public ticketList: TicketListItemViewComponent;

    constructor(protected ticketService: TicketService, protected settingsService: SettingsService,
        protected ticketActionsService: TicketActionsService, authenticationService: AuthenticationService) {
        super(ticketService, settingsService, ticketActionsService, authenticationService);
    }
}
