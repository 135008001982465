import { FindAffectedServiceAreasRequest } from "@iqModels/Tickets/FindAffectedServiceAreasRequest.model";

export class SearchAffectedServiceAreasRequest extends FindAffectedServiceAreasRequest {

    public SearchTerm: string;

    public DistanceToGeometry: number;

    public State?: string;
    public CountyName?: string;
    public PlaceName?: string;

    //  Service Areas that should be excluded from the results - i.e. They are already added to the ticket
    public ExcludeServiceAreaIDs?: string[];

    //  Would be better to enforce these as permission checks on the server.  But the search is done when manually
    //  adding service areas to a ticket or when searching to show on the map.  There are different permissions
    //  used in those different scenarios.  So at least for now, letting the client send this so we don't break
    //  ticket entry.
    /**
     * If set, the results will be limited to these Service Area IDs.  Used when the user only has permission
     * for a limited number of service areas (i.e. a Service Area user with view registration permission when we are
     * searching for service areas for them to view on a ticket map).
     */
    public LimitToServiceAreaIDs?: string[];

    constructor(geometryJson: object, ticketTypeID: string, locateTypeID: string, excludeServiceAreaIDs: string[], limitToServiceAreaIDs: string[]) {
        super(geometryJson, ticketTypeID, locateTypeID, null, null, null);

        this.ExcludeServiceAreaIDs = excludeServiceAreaIDs;
        this.LimitToServiceAreaIDs = limitToServiceAreaIDs;
    }

    public static CreateSearchTermRequest(searchTerm: string, ticketTypeID: string, locateTypeID: string,
        excludeServiceAreaIDs: string[], limitToServiceAreaIDs: string[], geometryJson: object, state: string, county: string): SearchAffectedServiceAreasRequest
    {
        const request = new SearchAffectedServiceAreasRequest(geometryJson, ticketTypeID, locateTypeID, excludeServiceAreaIDs, limitToServiceAreaIDs);
        request.SearchTerm = searchTerm;
        request.State = state;
        request.CountyName = county;
        return request;
    }

    public static CreateDistanceToGeomRequest(distanceToGeometry: number, geometryJson: object, ticketTypeID: string,
        locateTypeID: string, excludeServiceAreaIDs: string[], limitToServiceAreaIDs: string[], state: string, county: string): SearchAffectedServiceAreasRequest
    {
        const request = new SearchAffectedServiceAreasRequest(geometryJson, ticketTypeID, locateTypeID, excludeServiceAreaIDs, limitToServiceAreaIDs);
        request.DistanceToGeometry = distanceToGeometry;
        request.State = state;
        request.CountyName = county;
        return request;
    }

    public static CreateCountyRequest(state: string, countyName: string, ticketTypeID: string, locateTypeID: string,
        excludeServiceAreaIDs: string[], limitToServiceAreaIDs: string[], geometryJson: object): SearchAffectedServiceAreasRequest
    {
        const request = new SearchAffectedServiceAreasRequest(geometryJson, ticketTypeID, locateTypeID, excludeServiceAreaIDs, limitToServiceAreaIDs);
        request.State = state;
        request.CountyName = countyName;
        return request;
    }

    public static CreatePlaceRequest(state: string, countyName: string, placeName: string, ticketTypeID: string, locateTypeID: string,
        excludeServiceAreaIDs: string[], limitToServiceAreaIDs: string[], geometryJson: object): SearchAffectedServiceAreasRequest
    {
        const request = this.CreateCountyRequest(state, countyName, ticketTypeID, locateTypeID, excludeServiceAreaIDs, limitToServiceAreaIDs, geometryJson);
        request.PlaceName = placeName;
        return request;
    }
}
