import { NgControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Injector, AfterContentInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

import { EntryFormControl } from 'Shared/EntryFields/Forms/EntryFormControl';
import { FieldUIControlTypeEnum } from 'Enums/FieldUIControlType.enum';
import { DateEditorDirective } from 'Shared/Directives/DateEditor.directive';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from 'Services/SettingsService';

//  todo: This is using this component: https://danielykpan.github.io/date-time-picker/
//  It's not necessarily the best...
//  The time picker only uses 24 hour format and will be a pita to use (by clicking).  The author was not interested in making it support 12 hour formats.
//  Really need something that works well with input (keyboard entry) with a nice looking (optional) popup that cleanly supports both dates and times.
//  Something that works something like this one: https://codepen.io/alenaksu/pen/eNzbrZ
//      This is for old angular and there is no port to Angular2+ that I could find.
//      There are multiple forks like this one: https://github.com/dpoetzsch/md-pickers which are discussing porting to Angular2+
//  Another option (for date only and no good <input> integration) is https://kekeh.github.io/ngx-mydatepicker/ or https://github.com/AmolBorse/mydatepicker
//  Angular Material also has a date picker built in.
//  May need to manually handle the <input> part and just pick a date-only calendar control.  (the ngx-mydatepicker or mydatepicker look nicer than the one we're using here).

@Component({
    selector: 'iq-entry-field-date',
    template: `
<iq-entry-form-field [placeholderLabel]="Placeholder" [NoFloatingPlaceholder]="NoFloatingPlaceholder">
    <input iq-entry-field matInput [date-editor]="{ PickerType: PickerType, ForceTime: ForceTime, Min: Min, Max: Max}" #inputElement type="text" [ngStyle]="{'width':Width}" [min]="Min" [max]="Max" [owlDateTime]="dt1" [formControl]="FormControl" />
    <span [hidden]="Disabled | async" class="trigger" [owlDateTimeTrigger]="dt1">
        <i class="fas fa-calendar-alt" style="margin-left:5px"></i>
    </span>
    <owl-date-time #dt1 [pickerType]="PickerType" [stepMinute]="15" [hour12Timer]="!UsesMilitaryTime" (afterPickerClosed)="OnAfterPickerClosed()"></owl-date-time>
</iq-entry-form-field>
  `,
    styles: [
        ":host { max-width: 14em; }"
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EntryFieldDateComponent),
            multi: true
        }
    ]
})
export class EntryFieldDateComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
    public FormControl: EntryFormControl;

    public readonly Disabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //  'both' | 'calendar' | 'timer'
    @Input("PickerType")
    public PickerType: string = 'both';

    @Input("ForceTime")
    public ForceTime: string = null;

    @Input("Min")
    public Min: Date = null;

    @Input("Max")
    public Max: Date = null;

    @Input("placeholder")
    public Placeholder: string;

    //  Set to true to remove all of the top padding that is reserved for the floating placeholder.
    //  Defaults to false since most forms are already using this padding to separate the form fields.
    @Input("NoFloatingPlaceholder")
    public NoFloatingPlaceholder?: boolean;

    public get Width(): string {
        return this.PickerType === "calendar" ? "6em" : "11em";
    }

    public UsesMilitaryTime: boolean;

    @ViewChild("inputElement") _InputElement: ElementRef;
    @ViewChild(DateEditorDirective) _DateEditor: DateEditorDirective;

    private _Destroyed: Subject<void> = new Subject();

    constructor(private injector: Injector, settingsService: SettingsService) {
        this.UsesMilitaryTime = settingsService.UsesMilitaryTime;
    }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();

        this.FormControl = null;
        this._InputElement = null;
        this._DateEditor = null;
    }

    focus() {
        this._InputElement.nativeElement.focus();
    }

    //  Must use ngAfterContentInit because our EntryFormControl is not set until after the view has initialized.
    //  And then we also need to set the options list.
    //  If we do this in ngAfterViewInit, we will get an ExpressionChangedAfterItHasBeenCheckedError error.
    ngAfterContentInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        this.FormControl = ngControl.control as EntryFormControl;
        
        if (!this.FormControl.FieldConfiguration || (this.FormControl.FieldConfiguration.UIControlType !== FieldUIControlTypeEnum.DateTime)) {
            console.error("Date field is not DataType of DateTime", this.FormControl.FieldConfiguration);
            return;
        }

        this.Disabled.next(!this.FormControl.FieldIsEnabled());

        //  Need to subscribe to this to check for changes to FormControl.FieldIsEnabled().  DigSafe dynamically
        //  changes the ReadOnly value on WorkStart based on state/ticket type (and this event is triggered when that
        //  happens).  If we don't do this, the calendar icon does not show/hide correctly when that happens.  
        this.FormControl.CheckValid
            .pipe(takeUntil(this._Destroyed))
            .subscribe(() => {
                const isDisabled = !this.FormControl.FieldIsEnabled();
                if (this.Disabled.value !== isDisabled)
                    this.Disabled.next(isDisabled);
            });
    }

    writeValue(obj: any): void {
        //  Need to handle this because a change to the date could be triggered in response to a shortcut key or
        //  a change to the ticket type.  When that happens, the normal change event does not fire and that can result
        //  in the date being displayed unformatted (or default format by the date control).  This method still gets called
        //  so it gives us a chance to tell the date editor to format the date.
        //  Must do this in a timeout or whatever else is happening will format it AFTER this call so our formatting will be lost.
        //  It also messes up the date component selection (resets it to the beginning).
        setTimeout(() => {
            if (this._DateEditor)
                this._DateEditor.FormatDateString(obj);
        });
    }

    registerOnChange(fn: any): void {
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    OnAfterPickerClosed() {
        //  Set cursor to start and then focus.  This will make the date-editor auto select the first component.
        const targetElement = this._InputElement.nativeElement as HTMLInputElement;
        if (targetElement.setSelectionRange)
            targetElement.setSelectionRange(0, 0);

        this._InputElement.nativeElement.focus();
    } 
}
