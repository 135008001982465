<div style="display:flex; flex-direction: column; overflow:hidden">
    <div style="display:flex; align-items:baseline; justify-content:space-between">
        <ng-container *ngIf="DisplaySearchValues?.length > 10">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>filter</mat-label>
                <input matInput iqDisableAutofill type="search" [formControl]="FilterFormControl" />
            </mat-form-field>
        </ng-container>
        <div *ngIf="!RestrictListFilterToSingleSelection || (values?.length > 0)" style="display:flex; padding-top:15px; padding-bottom:15px">
            <div class="gray-primary-text">select:</div>
            <div class="link" *ngIf="!RestrictListFilterToSingleSelection" style="padding-left:20px" (click)="SelectAll()">all</div>
            <div class="link" *ngIf="values?.length > 0" style="padding-left:20px" (click)="SelectNone()">none</div>
        </div>
    </div>
    <!-- don't know why but the 10px padding is necessary to keep the scrollbar from showing unnecessarily -->
    <div class="static-search-items" style="overflow:auto; padding-bottom:10px">
        <!-- disabled also has this and don't know why: "|| (!allowEmpty && values.length === 1 && val.Selected)" -->
        <!-- that just causes the selected item to show as disabled when it's picked - why would we want that??? -->
        <mat-checkbox *ngFor="let val of FilteredSearchValues" class="nowrap" [disabled]="isDisabled"
                      [checked]="val.Selected" (change)="ToggleSearchValue($event.checked, val)">{{val.Name}}</mat-checkbox>
    </div>

    <!-- virtual scroll is WAY faster when there are lots of items (i.e. a service area filter on a report ) -->
    <!-- but it requires specifying a fixed height & width - could not find a way to make it expand up to a max size and then scroll.  -->
    <!-- to make it work, uncomment the height & width on the :host tag in the .css -->
    <!--<div class="static-search-items" style="overflow:auto; padding-bottom:10px; flex-grow:1">
        <cdk-virtual-scroll-viewport itemSize="25" style="height:100%; display:flex">
            <div *cdkVirtualFor="let val of FilteredSearchValues">
                <mat-checkbox class="nowrap" color="primary" [disabled]="isDisabled"
                              [checked]="val.Selected" (change)="ToggleSearchValue($event, val)">{{val.Name}}</mat-checkbox>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>-->
</div>
